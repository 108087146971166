/* tslint:disable */
/* eslint-disable */
/**
 * NGM Merchant Backend API (APIM)
 * Main API of the Sodexo NGM project
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Bulk } from '../models';
// @ts-ignore
import { CodesResponse } from '../models';
// @ts-ignore
import { CountResponse } from '../models';
// @ts-ignore
import { ErrorResponse } from '../models';
// @ts-ignore
import { ExportResponse } from '../models';
// @ts-ignore
import { InvoicesResponse } from '../models';
// @ts-ignore
import { PackagesResponse } from '../models';
// @ts-ignore
import { PerformanceResponse } from '../models';
// @ts-ignore
import { ScheduledTaskListResponse } from '../models';
// @ts-ignore
import { TransactionClaimRequest } from '../models';
// @ts-ignore
import { TransactionClaimResponse } from '../models';
// @ts-ignore
import { TransactionClaimableAmountResponse } from '../models';
// @ts-ignore
import { TransactionStatusEnum } from '../models';
// @ts-ignore
import { TransactionsHistoryStatusResponse } from '../models';
// @ts-ignore
import { TransactionsResponse } from '../models';
/**
 * BusinessActivityApi - axios parameter creator
 * @export
 */
export const BusinessActivityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get all enums from BAM
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bamCodes: async (ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/business-activity-reports/codes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Contract invoice file donwload
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {string} invoiceNumber Invoice id from external system
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractInvoiceDownload: async (contractUid: string, invoiceNumber: string, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractUid' is not null or undefined
            assertParamExists('contractInvoiceDownload', 'contractUid', contractUid)
            // verify required parameter 'invoiceNumber' is not null or undefined
            assertParamExists('contractInvoiceDownload', 'invoiceNumber', invoiceNumber)
            const localVarPath = `/api/contract/{contractUid}/invoice/{invoiceNumber}/file`
                .replace(`{${"contractUid"}}`, encodeURIComponent(String(contractUid)))
                .replace(`{${"invoiceNumber"}}`, encodeURIComponent(String(invoiceNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Contract invoices search
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {number} [pageNumber] Results page number
         * @param {number} [pageSize] Results page size
         * @param {string} [fulltext] Fulltext search string
         * @param {string} [invoicedFrom] Filter invoiced from date
         * @param {string} [invoicedTill] Filter invoiced till date
         * @param {string} [dueFrom] Filter due from date
         * @param {string} [dueTill] Filter due till date
         * @param {number} [amountFrom] Transaction amount from
         * @param {number} [amountTill] Transaction amount till
         * @param {string} [invoiceNo] Invoice number
         * @param {boolean} [isPaid] Invoice was payed
         * @param {Array<string>} [locationMasterId] Location ID from the BO system
         * @param {Array<string>} [productType] Product type
         * @param {Array<string>} [invoiceType] invoice type
         * @param {string} [sortBy] Set column for sorting
         * @param {boolean} [sortDesc] Describe if sort is descendinf
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractInvoices: async (contractUid: string, ngmCountry?: 'cz' | 'be', pageNumber?: number, pageSize?: number, fulltext?: string, invoicedFrom?: string, invoicedTill?: string, dueFrom?: string, dueTill?: string, amountFrom?: number, amountTill?: number, invoiceNo?: string, isPaid?: boolean, locationMasterId?: Array<string>, productType?: Array<string>, invoiceType?: Array<string>, sortBy?: string, sortDesc?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractUid' is not null or undefined
            assertParamExists('contractInvoices', 'contractUid', contractUid)
            const localVarPath = `/api/contract/{contractUid}/invoices`
                .replace(`{${"contractUid"}}`, encodeURIComponent(String(contractUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (fulltext !== undefined) {
                localVarQueryParameter['fulltext'] = fulltext;
            }

            if (invoicedFrom !== undefined) {
                localVarQueryParameter['invoicedFrom'] = invoicedFrom;
            }

            if (invoicedTill !== undefined) {
                localVarQueryParameter['invoicedTill'] = invoicedTill;
            }

            if (dueFrom !== undefined) {
                localVarQueryParameter['dueFrom'] = dueFrom;
            }

            if (dueTill !== undefined) {
                localVarQueryParameter['dueTill'] = dueTill;
            }

            if (amountFrom !== undefined) {
                localVarQueryParameter['amountFrom'] = amountFrom;
            }

            if (amountTill !== undefined) {
                localVarQueryParameter['amountTill'] = amountTill;
            }

            if (invoiceNo !== undefined) {
                localVarQueryParameter['invoiceNo'] = invoiceNo;
            }

            if (isPaid !== undefined) {
                localVarQueryParameter['isPaid'] = isPaid;
            }

            if (locationMasterId) {
                localVarQueryParameter['locationMasterId'] = locationMasterId.join(COLLECTION_FORMATS.csv);
            }

            if (productType) {
                localVarQueryParameter['productType'] = productType.join(COLLECTION_FORMATS.csv);
            }

            if (invoiceType) {
                localVarQueryParameter['invoiceType'] = invoiceType.join(COLLECTION_FORMATS.csv);
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortDesc !== undefined) {
                localVarQueryParameter['sortDesc'] = sortDesc;
            }

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create contract invoices bulk request
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {string} [invoicedFrom] Filter invoiced from date
         * @param {string} [invoicedTill] Filter invoiced till date
         * @param {string} [dueFrom] Filter due from date
         * @param {string} [dueTill] Filter due till date
         * @param {number} [amountFrom] Transaction amount from
         * @param {number} [amountTill] Transaction amount till
         * @param {boolean} [isPaid] Invoice was payed
         * @param {Array<string>} [locationMasterId] Location ID from the BO system
         * @param {Array<string>} [productType] Product type
         * @param {Array<string>} [invoiceType] invoice type
         * @param {Array<string>} [invoiceIdsOnly] Location ID from the BO system
         * @param {Array<string>} [invoiceIdsExcluded] Location ID from the BO system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractInvoicesCreateBulk: async (contractUid: string, ngmCountry?: 'cz' | 'be', invoicedFrom?: string, invoicedTill?: string, dueFrom?: string, dueTill?: string, amountFrom?: number, amountTill?: number, isPaid?: boolean, locationMasterId?: Array<string>, productType?: Array<string>, invoiceType?: Array<string>, invoiceIdsOnly?: Array<string>, invoiceIdsExcluded?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractUid' is not null or undefined
            assertParamExists('contractInvoicesCreateBulk', 'contractUid', contractUid)
            const localVarPath = `/api/contract/{contractUid}/invoices/bulk`
                .replace(`{${"contractUid"}}`, encodeURIComponent(String(contractUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (invoicedFrom !== undefined) {
                localVarQueryParameter['invoicedFrom'] = invoicedFrom;
            }

            if (invoicedTill !== undefined) {
                localVarQueryParameter['invoicedTill'] = invoicedTill;
            }

            if (dueFrom !== undefined) {
                localVarQueryParameter['dueFrom'] = dueFrom;
            }

            if (dueTill !== undefined) {
                localVarQueryParameter['dueTill'] = dueTill;
            }

            if (amountFrom !== undefined) {
                localVarQueryParameter['amountFrom'] = amountFrom;
            }

            if (amountTill !== undefined) {
                localVarQueryParameter['amountTill'] = amountTill;
            }

            if (isPaid !== undefined) {
                localVarQueryParameter['isPaid'] = isPaid;
            }

            if (locationMasterId) {
                localVarQueryParameter['locationMasterId'] = locationMasterId.join(COLLECTION_FORMATS.csv);
            }

            if (productType) {
                localVarQueryParameter['productType'] = productType.join(COLLECTION_FORMATS.csv);
            }

            if (invoiceType) {
                localVarQueryParameter['invoiceType'] = invoiceType.join(COLLECTION_FORMATS.csv);
            }

            if (invoiceIdsOnly) {
                localVarQueryParameter['invoiceIdsOnly'] = invoiceIdsOnly.join(COLLECTION_FORMATS.csv);
            }

            if (invoiceIdsExcluded) {
                localVarQueryParameter['invoiceIdsExcluded'] = invoiceIdsExcluded.join(COLLECTION_FORMATS.csv);
            }

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create contract invoices export request
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {string} [fulltext] Fulltext search string
         * @param {string} [invoicedFrom] Filter invoiced from date
         * @param {string} [invoicedTill] Filter invoiced till date
         * @param {string} [dueFrom] Filter due from date
         * @param {string} [dueTill] Filter due till date
         * @param {number} [amountFrom] Transaction amount from
         * @param {number} [amountTill] Transaction amount till
         * @param {string} [invoiceNo] Invoice number
         * @param {boolean} [isPaid] Invoice was payed
         * @param {Array<string>} [locationMasterId] Location ID from the BO system
         * @param {Array<string>} [productType] Product type
         * @param {Array<string>} [invoiceType] invoice type
         * @param {string} [sortBy] Set column for sorting
         * @param {boolean} [sortDesc] Describe if sort is descendinf
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractInvoicesCreateExport: async (contractUid: string, ngmCountry?: 'cz' | 'be', fulltext?: string, invoicedFrom?: string, invoicedTill?: string, dueFrom?: string, dueTill?: string, amountFrom?: number, amountTill?: number, invoiceNo?: string, isPaid?: boolean, locationMasterId?: Array<string>, productType?: Array<string>, invoiceType?: Array<string>, sortBy?: string, sortDesc?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractUid' is not null or undefined
            assertParamExists('contractInvoicesCreateExport', 'contractUid', contractUid)
            const localVarPath = `/api/contract/{contractUid}/invoices/export`
                .replace(`{${"contractUid"}}`, encodeURIComponent(String(contractUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fulltext !== undefined) {
                localVarQueryParameter['fulltext'] = fulltext;
            }

            if (invoicedFrom !== undefined) {
                localVarQueryParameter['invoicedFrom'] = invoicedFrom;
            }

            if (invoicedTill !== undefined) {
                localVarQueryParameter['invoicedTill'] = invoicedTill;
            }

            if (dueFrom !== undefined) {
                localVarQueryParameter['dueFrom'] = dueFrom;
            }

            if (dueTill !== undefined) {
                localVarQueryParameter['dueTill'] = dueTill;
            }

            if (amountFrom !== undefined) {
                localVarQueryParameter['amountFrom'] = amountFrom;
            }

            if (amountTill !== undefined) {
                localVarQueryParameter['amountTill'] = amountTill;
            }

            if (invoiceNo !== undefined) {
                localVarQueryParameter['invoiceNo'] = invoiceNo;
            }

            if (isPaid !== undefined) {
                localVarQueryParameter['isPaid'] = isPaid;
            }

            if (locationMasterId) {
                localVarQueryParameter['locationMasterId'] = locationMasterId.join(COLLECTION_FORMATS.csv);
            }

            if (productType) {
                localVarQueryParameter['productType'] = productType.join(COLLECTION_FORMATS.csv);
            }

            if (invoiceType) {
                localVarQueryParameter['invoiceType'] = invoiceType.join(COLLECTION_FORMATS.csv);
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortDesc !== undefined) {
                localVarQueryParameter['sortDesc'] = sortDesc;
            }

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get contract invoices export file
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractInvoicesDownloadExport: async (contractUid: string, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractUid' is not null or undefined
            assertParamExists('contractInvoicesDownloadExport', 'contractUid', contractUid)
            const localVarPath = `/api/contract/{contractUid}/invoices/export`
                .replace(`{${"contractUid"}}`, encodeURIComponent(String(contractUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Contract packages search
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {number} [pageNumber] Results page number
         * @param {number} [pageSize] Results page size
         * @param {string} [fulltext] Fulltext search string
         * @param {string} [deliveryFrom] Filter invoiced from date
         * @param {string} [deliveryTill] Filter invoiced till date
         * @param {string} [dueFrom] Filter due from date
         * @param {string} [dueTill] Filter due till date
         * @param {string} [paymentFrom] Filter payment from date
         * @param {string} [paymentTill] Filter payment till date
         * @param {number} [amountFrom] Transaction amount from
         * @param {number} [amountTill] Transaction amount till
         * @param {Array<string>} [invoiceNo] Invoice number
         * @param {Array<string>} [packageNo] package number
         * @param {Array<string>} [locationMasterId] Location ID from the BO system
         * @param {string} [deliveryChannel] Delivery channel
         * @param {string} [sortBy] Set column for sorting
         * @param {boolean} [sortDesc] Describe if sort is descendinf
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractPackages: async (contractUid: string, ngmCountry?: 'cz' | 'be', pageNumber?: number, pageSize?: number, fulltext?: string, deliveryFrom?: string, deliveryTill?: string, dueFrom?: string, dueTill?: string, paymentFrom?: string, paymentTill?: string, amountFrom?: number, amountTill?: number, invoiceNo?: Array<string>, packageNo?: Array<string>, locationMasterId?: Array<string>, deliveryChannel?: string, sortBy?: string, sortDesc?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractUid' is not null or undefined
            assertParamExists('contractPackages', 'contractUid', contractUid)
            const localVarPath = `/api/contract/{contractUid}/packages`
                .replace(`{${"contractUid"}}`, encodeURIComponent(String(contractUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (fulltext !== undefined) {
                localVarQueryParameter['fulltext'] = fulltext;
            }

            if (deliveryFrom !== undefined) {
                localVarQueryParameter['deliveryFrom'] = deliveryFrom;
            }

            if (deliveryTill !== undefined) {
                localVarQueryParameter['deliveryTill'] = deliveryTill;
            }

            if (dueFrom !== undefined) {
                localVarQueryParameter['dueFrom'] = dueFrom;
            }

            if (dueTill !== undefined) {
                localVarQueryParameter['dueTill'] = dueTill;
            }

            if (paymentFrom !== undefined) {
                localVarQueryParameter['paymentFrom'] = paymentFrom;
            }

            if (paymentTill !== undefined) {
                localVarQueryParameter['paymentTill'] = paymentTill;
            }

            if (amountFrom !== undefined) {
                localVarQueryParameter['amountFrom'] = amountFrom;
            }

            if (amountTill !== undefined) {
                localVarQueryParameter['amountTill'] = amountTill;
            }

            if (invoiceNo) {
                localVarQueryParameter['invoiceNo'] = invoiceNo.join(COLLECTION_FORMATS.csv);
            }

            if (packageNo) {
                localVarQueryParameter['packageNo'] = packageNo.join(COLLECTION_FORMATS.csv);
            }

            if (locationMasterId) {
                localVarQueryParameter['locationMasterId'] = locationMasterId.join(COLLECTION_FORMATS.csv);
            }

            if (deliveryChannel !== undefined) {
                localVarQueryParameter['deliveryChannel'] = deliveryChannel;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortDesc !== undefined) {
                localVarQueryParameter['sortDesc'] = sortDesc;
            }

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create contract packages export request
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {string} [fulltext] Fulltext search string
         * @param {string} [deliveryFrom] Filter invoiced from date
         * @param {string} [deliveryTill] Filter invoiced till date
         * @param {string} [dueFrom] Filter due from date
         * @param {string} [dueTill] Filter due till date
         * @param {string} [paymentFrom] Filter payment from date
         * @param {string} [paymentTill] Filter payment till date
         * @param {number} [amountFrom] Transaction amount from
         * @param {number} [amountTill] Transaction amount till
         * @param {Array<string>} [invoiceNo] Invoice number
         * @param {Array<string>} [packageNo] package number
         * @param {Array<string>} [locationMasterId] Location ID from the BO system
         * @param {string} [deliveryChannel] Delivery channel
         * @param {string} [sortBy] Set column for sorting
         * @param {boolean} [sortDesc] Describe if sort is descendinf
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractPackagesCreateExport: async (contractUid: string, ngmCountry?: 'cz' | 'be', fulltext?: string, deliveryFrom?: string, deliveryTill?: string, dueFrom?: string, dueTill?: string, paymentFrom?: string, paymentTill?: string, amountFrom?: number, amountTill?: number, invoiceNo?: Array<string>, packageNo?: Array<string>, locationMasterId?: Array<string>, deliveryChannel?: string, sortBy?: string, sortDesc?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractUid' is not null or undefined
            assertParamExists('contractPackagesCreateExport', 'contractUid', contractUid)
            const localVarPath = `/api/contract/{contractUid}/packages/export`
                .replace(`{${"contractUid"}}`, encodeURIComponent(String(contractUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fulltext !== undefined) {
                localVarQueryParameter['fulltext'] = fulltext;
            }

            if (deliveryFrom !== undefined) {
                localVarQueryParameter['deliveryFrom'] = deliveryFrom;
            }

            if (deliveryTill !== undefined) {
                localVarQueryParameter['deliveryTill'] = deliveryTill;
            }

            if (dueFrom !== undefined) {
                localVarQueryParameter['dueFrom'] = dueFrom;
            }

            if (dueTill !== undefined) {
                localVarQueryParameter['dueTill'] = dueTill;
            }

            if (paymentFrom !== undefined) {
                localVarQueryParameter['paymentFrom'] = paymentFrom;
            }

            if (paymentTill !== undefined) {
                localVarQueryParameter['paymentTill'] = paymentTill;
            }

            if (amountFrom !== undefined) {
                localVarQueryParameter['amountFrom'] = amountFrom;
            }

            if (amountTill !== undefined) {
                localVarQueryParameter['amountTill'] = amountTill;
            }

            if (invoiceNo) {
                localVarQueryParameter['invoiceNo'] = invoiceNo.join(COLLECTION_FORMATS.csv);
            }

            if (packageNo) {
                localVarQueryParameter['packageNo'] = packageNo.join(COLLECTION_FORMATS.csv);
            }

            if (locationMasterId) {
                localVarQueryParameter['locationMasterId'] = locationMasterId.join(COLLECTION_FORMATS.csv);
            }

            if (deliveryChannel !== undefined) {
                localVarQueryParameter['deliveryChannel'] = deliveryChannel;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortDesc !== undefined) {
                localVarQueryParameter['sortDesc'] = sortDesc;
            }

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get contract packages export file
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractPackagesDownloadExport: async (contractUid: string, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractUid' is not null or undefined
            assertParamExists('contractPackagesDownloadExport', 'contractUid', contractUid)
            const localVarPath = `/api/contract/{contractUid}/packages/export`
                .replace(`{${"contractUid"}}`, encodeURIComponent(String(contractUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of active exports for contract and user
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractScheduledTasks: async (contractUid: string, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractUid' is not null or undefined
            assertParamExists('contractScheduledTasks', 'contractUid', contractUid)
            const localVarPath = `/api/contract/{contractUid}/scheduled-tasks`
                .replace(`{${"contractUid"}}`, encodeURIComponent(String(contractUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Contract transactions claim
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {string} transactionCode Master ID of the transaction. Also known as \&#39;internal ID\&#39;
         * @param {TransactionClaimRequest} transactionClaimRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractTransactionClaim: async (contractUid: string, transactionCode: string, transactionClaimRequest: TransactionClaimRequest, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractUid' is not null or undefined
            assertParamExists('contractTransactionClaim', 'contractUid', contractUid)
            // verify required parameter 'transactionCode' is not null or undefined
            assertParamExists('contractTransactionClaim', 'transactionCode', transactionCode)
            // verify required parameter 'transactionClaimRequest' is not null or undefined
            assertParamExists('contractTransactionClaim', 'transactionClaimRequest', transactionClaimRequest)
            const localVarPath = `/api/contract/{contractUid}/transaction/{transactionCode}`
                .replace(`{${"contractUid"}}`, encodeURIComponent(String(contractUid)))
                .replace(`{${"transactionCode"}}`, encodeURIComponent(String(transactionCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(transactionClaimRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Contract transaction claimable amount
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {string} transactionCode Master ID of the transaction. Also known as \&#39;internal ID\&#39;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractTransactionClaimableAmount: async (contractUid: string, transactionCode: string, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractUid' is not null or undefined
            assertParamExists('contractTransactionClaimableAmount', 'contractUid', contractUid)
            // verify required parameter 'transactionCode' is not null or undefined
            assertParamExists('contractTransactionClaimableAmount', 'transactionCode', transactionCode)
            const localVarPath = `/api/contract/{contractUid}/transaction/{transactionCode}`
                .replace(`{${"contractUid"}}`, encodeURIComponent(String(contractUid)))
                .replace(`{${"transactionCode"}}`, encodeURIComponent(String(transactionCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Contract transactions search
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {number} [pageNumber] Results page number
         * @param {number} [pageSize] Results page size
         * @param {string} [fulltext] Fulltext search string
         * @param {string} [note] Filter note search string
         * @param {string} [createdFrom] Filter created from date
         * @param {string} [createdTill] Filter created till date
         * @param {number} [amountFrom] Transaction amount from
         * @param {number} [amountTill] Transaction amount till
         * @param {Array<string>} [invoiceNo] Invoice number
         * @param {Array<string>} [locationMasterId] Location ID from the BO system
         * @param {string} [orderMasterId] Order ID from the BO system
         * @param {string} [extPaymentId] Id of the original claim request from NGM.
         * @param {Array<string>} [terminalMasterId] POS Terminal ID from the BO system
         * @param {Array<string>} [physicalTerminalId] POS Terminal ID from the BO system
         * @param {Array<string>} [authCode] Authorization codes. Also known as \&#39;approval code\&#39;.
         * @param {Array<string>} [productCode] Product codes
         * @param {Array<string>} [transactionType] Transaction types
         * @param {Array<string>} [paymentMethod] Payment methods
         * @param {string} [sortBy] Set column for sorting
         * @param {Array<TransactionStatusEnum>} [status] Status of the transactions
         * @param {boolean} [sortDesc] Describe if sort is descendinf
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractTransactions: async (contractUid: string, ngmCountry?: 'cz' | 'be', pageNumber?: number, pageSize?: number, fulltext?: string, note?: string, createdFrom?: string, createdTill?: string, amountFrom?: number, amountTill?: number, invoiceNo?: Array<string>, locationMasterId?: Array<string>, orderMasterId?: string, extPaymentId?: string, terminalMasterId?: Array<string>, physicalTerminalId?: Array<string>, authCode?: Array<string>, productCode?: Array<string>, transactionType?: Array<string>, paymentMethod?: Array<string>, sortBy?: string, status?: Array<TransactionStatusEnum>, sortDesc?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractUid' is not null or undefined
            assertParamExists('contractTransactions', 'contractUid', contractUid)
            const localVarPath = `/api/contract/{contractUid}/transactions`
                .replace(`{${"contractUid"}}`, encodeURIComponent(String(contractUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (fulltext !== undefined) {
                localVarQueryParameter['fulltext'] = fulltext;
            }

            if (note !== undefined) {
                localVarQueryParameter['note'] = note;
            }

            if (createdFrom !== undefined) {
                localVarQueryParameter['createdFrom'] = createdFrom;
            }

            if (createdTill !== undefined) {
                localVarQueryParameter['createdTill'] = createdTill;
            }

            if (amountFrom !== undefined) {
                localVarQueryParameter['amountFrom'] = amountFrom;
            }

            if (amountTill !== undefined) {
                localVarQueryParameter['amountTill'] = amountTill;
            }

            if (invoiceNo) {
                localVarQueryParameter['invoiceNo'] = invoiceNo.join(COLLECTION_FORMATS.csv);
            }

            if (locationMasterId) {
                localVarQueryParameter['locationMasterId'] = locationMasterId.join(COLLECTION_FORMATS.csv);
            }

            if (orderMasterId !== undefined) {
                localVarQueryParameter['orderMasterId'] = orderMasterId;
            }

            if (extPaymentId !== undefined) {
                localVarQueryParameter['extPaymentId'] = extPaymentId;
            }

            if (terminalMasterId) {
                localVarQueryParameter['terminalMasterId'] = terminalMasterId.join(COLLECTION_FORMATS.csv);
            }

            if (physicalTerminalId) {
                localVarQueryParameter['physicalTerminalId'] = physicalTerminalId.join(COLLECTION_FORMATS.csv);
            }

            if (authCode) {
                localVarQueryParameter['authCode'] = authCode.join(COLLECTION_FORMATS.csv);
            }

            if (productCode) {
                localVarQueryParameter['productCode'] = productCode.join(COLLECTION_FORMATS.csv);
            }

            if (transactionType) {
                localVarQueryParameter['transactionType'] = transactionType.join(COLLECTION_FORMATS.csv);
            }

            if (paymentMethod) {
                localVarQueryParameter['paymentMethod'] = paymentMethod.join(COLLECTION_FORMATS.csv);
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (status) {
                localVarQueryParameter['status'] = status.join(COLLECTION_FORMATS.csv);
            }

            if (sortDesc !== undefined) {
                localVarQueryParameter['sortDesc'] = sortDesc;
            }

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Contract transactions count
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {string} [note] Filter note search string
         * @param {string} [fulltext] Fulltext search string
         * @param {string} [createdFrom] Filter created from date
         * @param {string} [createdTill] Filter created till date
         * @param {number} [amountFrom] Transaction amount from
         * @param {number} [amountTill] Transaction amount till
         * @param {Array<string>} [invoiceNo] Invoice number
         * @param {Array<string>} [locationMasterId] Location ID from the BO system
         * @param {string} [orderMasterId] Order ID from the BO system
         * @param {Array<string>} [terminalMasterId] POS Terminal ID from the BO system
         * @param {Array<string>} [physicalTerminalId] Physical terminal ID from the BO system
         * @param {Array<string>} [authCode] Authorization codes. Also known as \&#39;approval code\&#39;.
         * @param {Array<string>} [productCode] Product codes
         * @param {Array<string>} [transactionType] Transaction types
         * @param {Array<string>} [paymentMethod] Payment methods
         * @param {Array<TransactionStatusEnum>} [status] Status of the transactions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractTransactionsCount: async (contractUid: string, ngmCountry?: 'cz' | 'be', note?: string, fulltext?: string, createdFrom?: string, createdTill?: string, amountFrom?: number, amountTill?: number, invoiceNo?: Array<string>, locationMasterId?: Array<string>, orderMasterId?: string, terminalMasterId?: Array<string>, physicalTerminalId?: Array<string>, authCode?: Array<string>, productCode?: Array<string>, transactionType?: Array<string>, paymentMethod?: Array<string>, status?: Array<TransactionStatusEnum>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractUid' is not null or undefined
            assertParamExists('contractTransactionsCount', 'contractUid', contractUid)
            const localVarPath = `/api/contract/{contractUid}/transactions-count`
                .replace(`{${"contractUid"}}`, encodeURIComponent(String(contractUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (note !== undefined) {
                localVarQueryParameter['note'] = note;
            }

            if (fulltext !== undefined) {
                localVarQueryParameter['fulltext'] = fulltext;
            }

            if (createdFrom !== undefined) {
                localVarQueryParameter['createdFrom'] = createdFrom;
            }

            if (createdTill !== undefined) {
                localVarQueryParameter['createdTill'] = createdTill;
            }

            if (amountFrom !== undefined) {
                localVarQueryParameter['amountFrom'] = amountFrom;
            }

            if (amountTill !== undefined) {
                localVarQueryParameter['amountTill'] = amountTill;
            }

            if (invoiceNo) {
                localVarQueryParameter['invoiceNo'] = invoiceNo.join(COLLECTION_FORMATS.csv);
            }

            if (locationMasterId) {
                localVarQueryParameter['locationMasterId'] = locationMasterId.join(COLLECTION_FORMATS.csv);
            }

            if (orderMasterId !== undefined) {
                localVarQueryParameter['orderMasterId'] = orderMasterId;
            }

            if (terminalMasterId) {
                localVarQueryParameter['terminalMasterId'] = terminalMasterId.join(COLLECTION_FORMATS.csv);
            }

            if (physicalTerminalId) {
                localVarQueryParameter['physicalTerminalId'] = physicalTerminalId.join(COLLECTION_FORMATS.csv);
            }

            if (authCode) {
                localVarQueryParameter['authCode'] = authCode.join(COLLECTION_FORMATS.csv);
            }

            if (productCode) {
                localVarQueryParameter['productCode'] = productCode.join(COLLECTION_FORMATS.csv);
            }

            if (transactionType) {
                localVarQueryParameter['transactionType'] = transactionType.join(COLLECTION_FORMATS.csv);
            }

            if (paymentMethod) {
                localVarQueryParameter['paymentMethod'] = paymentMethod.join(COLLECTION_FORMATS.csv);
            }

            if (status) {
                localVarQueryParameter['status'] = status.join(COLLECTION_FORMATS.csv);
            }

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create contract transactions export request
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {string} [note] Filter note search string
         * @param {string} [createdFrom] Filter created from date
         * @param {string} [createdTill] Filter created till date
         * @param {number} [amountFrom] Transaction amount from
         * @param {number} [amountTill] Transaction amount till
         * @param {Array<string>} [invoiceNo] Invoice number
         * @param {Array<string>} [locationMasterId] Location ID from the BO system
         * @param {string} [orderMasterId] Order ID from the BO system
         * @param {Array<string>} [physicalTerminalId] POS Terminal ID from the BO system
         * @param {Array<string>} [terminalMasterId] POS Terminal ID from the BO system
         * @param {Array<string>} [authCode] Authorization codes. Also known as \&#39;approval code\&#39;.
         * @param {Array<string>} [productCode] Product codes
         * @param {Array<string>} [transactionType] Transaction types
         * @param {Array<string>} [paymentMethod] Payment methods
         * @param {string} [sortBy] Set column for sorting
         * @param {Array<TransactionStatusEnum>} [status] Status of the transactions
         * @param {boolean} [sortDesc] Describe if sort is descendinf
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractTransactionsCreateExport: async (contractUid: string, ngmCountry?: 'cz' | 'be', note?: string, createdFrom?: string, createdTill?: string, amountFrom?: number, amountTill?: number, invoiceNo?: Array<string>, locationMasterId?: Array<string>, orderMasterId?: string, physicalTerminalId?: Array<string>, terminalMasterId?: Array<string>, authCode?: Array<string>, productCode?: Array<string>, transactionType?: Array<string>, paymentMethod?: Array<string>, sortBy?: string, status?: Array<TransactionStatusEnum>, sortDesc?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractUid' is not null or undefined
            assertParamExists('contractTransactionsCreateExport', 'contractUid', contractUid)
            const localVarPath = `/api/contract/{contractUid}/transactions/export`
                .replace(`{${"contractUid"}}`, encodeURIComponent(String(contractUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (note !== undefined) {
                localVarQueryParameter['note'] = note;
            }

            if (createdFrom !== undefined) {
                localVarQueryParameter['createdFrom'] = createdFrom;
            }

            if (createdTill !== undefined) {
                localVarQueryParameter['createdTill'] = createdTill;
            }

            if (amountFrom !== undefined) {
                localVarQueryParameter['amountFrom'] = amountFrom;
            }

            if (amountTill !== undefined) {
                localVarQueryParameter['amountTill'] = amountTill;
            }

            if (invoiceNo) {
                localVarQueryParameter['invoiceNo'] = invoiceNo.join(COLLECTION_FORMATS.csv);
            }

            if (locationMasterId) {
                localVarQueryParameter['locationMasterId'] = locationMasterId.join(COLLECTION_FORMATS.csv);
            }

            if (orderMasterId !== undefined) {
                localVarQueryParameter['orderMasterId'] = orderMasterId;
            }

            if (physicalTerminalId) {
                localVarQueryParameter['physicalTerminalId'] = physicalTerminalId.join(COLLECTION_FORMATS.csv);
            }

            if (terminalMasterId) {
                localVarQueryParameter['terminalMasterId'] = terminalMasterId.join(COLLECTION_FORMATS.csv);
            }

            if (authCode) {
                localVarQueryParameter['authCode'] = authCode.join(COLLECTION_FORMATS.csv);
            }

            if (productCode) {
                localVarQueryParameter['productCode'] = productCode.join(COLLECTION_FORMATS.csv);
            }

            if (transactionType) {
                localVarQueryParameter['transactionType'] = transactionType.join(COLLECTION_FORMATS.csv);
            }

            if (paymentMethod) {
                localVarQueryParameter['paymentMethod'] = paymentMethod.join(COLLECTION_FORMATS.csv);
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (status) {
                localVarQueryParameter['status'] = status.join(COLLECTION_FORMATS.csv);
            }

            if (sortDesc !== undefined) {
                localVarQueryParameter['sortDesc'] = sortDesc;
            }

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get contract transactions export file
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractTransactionsDownloadExport: async (contractUid: string, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractUid' is not null or undefined
            assertParamExists('contractTransactionsDownloadExport', 'contractUid', contractUid)
            const localVarPath = `/api/contract/{contractUid}/transactions/export`
                .replace(`{${"contractUid"}}`, encodeURIComponent(String(contractUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Performance business report
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'year' | 'month'} displayedBy Choose view type
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {number} [pageNumber] Results page number
         * @param {number} [pageSize] Results page size
         * @param {Array<string>} [locationMasterId] Location ID from the BO system
         * @param {'year' | 'month'} [sortBy] Set column for sorting
         * @param {boolean} [sortDesc] Describe if sort is descending
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performanceReport: async (contractUid: string, displayedBy: 'year' | 'month', ngmCountry?: 'cz' | 'be', pageNumber?: number, pageSize?: number, locationMasterId?: Array<string>, sortBy?: 'year' | 'month', sortDesc?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractUid' is not null or undefined
            assertParamExists('performanceReport', 'contractUid', contractUid)
            // verify required parameter 'displayedBy' is not null or undefined
            assertParamExists('performanceReport', 'displayedBy', displayedBy)
            const localVarPath = `/api/contract/{contractUid}/performance-report`
                .replace(`{${"contractUid"}}`, encodeURIComponent(String(contractUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (displayedBy !== undefined) {
                localVarQueryParameter['displayedBy'] = displayedBy;
            }

            if (locationMasterId) {
                localVarQueryParameter['locationMasterId'] = locationMasterId.join(COLLECTION_FORMATS.csv);
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortDesc !== undefined) {
                localVarQueryParameter['sortDesc'] = sortDesc;
            }

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create contract performance report export request
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'year' | 'month'} displayedBy Choose view type
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {Array<string>} [locationMasterId] Location ID from the BO system
         * @param {'year' | 'month'} [sortBy] Set column for sorting
         * @param {boolean} [sortDesc] Describe if sort is descending
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performanceReportCreateExport: async (contractUid: string, displayedBy: 'year' | 'month', ngmCountry?: 'cz' | 'be', locationMasterId?: Array<string>, sortBy?: 'year' | 'month', sortDesc?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractUid' is not null or undefined
            assertParamExists('performanceReportCreateExport', 'contractUid', contractUid)
            // verify required parameter 'displayedBy' is not null or undefined
            assertParamExists('performanceReportCreateExport', 'displayedBy', displayedBy)
            const localVarPath = `/api/contract/{contractUid}/performance-report/export`
                .replace(`{${"contractUid"}}`, encodeURIComponent(String(contractUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (displayedBy !== undefined) {
                localVarQueryParameter['displayedBy'] = displayedBy;
            }

            if (locationMasterId) {
                localVarQueryParameter['locationMasterId'] = locationMasterId.join(COLLECTION_FORMATS.csv);
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortDesc !== undefined) {
                localVarQueryParameter['sortDesc'] = sortDesc;
            }

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get contract performance report export file
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performanceReportDownloadExport: async (contractUid: string, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractUid' is not null or undefined
            assertParamExists('performanceReportDownloadExport', 'contractUid', contractUid)
            const localVarPath = `/api/contract/{contractUid}/performance-report/export`
                .replace(`{${"contractUid"}}`, encodeURIComponent(String(contractUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Request for push the contract transactions list into the BAM, from specific date till oldest records in the database
         * @summary Submit request for historical contract transactions
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {string} [createdTill] Records created from date
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionsHistoryRequest: async (contractUid: string, createdTill?: string, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractUid' is not null or undefined
            assertParamExists('transactionsHistoryRequest', 'contractUid', contractUid)
            const localVarPath = `/api/contract/{contractUid}/transactions-history-request`
                .replace(`{${"contractUid"}}`, encodeURIComponent(String(contractUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (createdTill !== undefined) {
                localVarQueryParameter['createdTill'] = createdTill;
            }

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get status of the latest request to obtain historical transations from the BO and timestamp of the latest available record (related with the contract)
         * @summary Status of the latest request to historical transations
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionsHistoryState: async (contractUid: string, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractUid' is not null or undefined
            assertParamExists('transactionsHistoryState', 'contractUid', contractUid)
            const localVarPath = `/api/contract/{contractUid}/transactions-history-state`
                .replace(`{${"contractUid"}}`, encodeURIComponent(String(contractUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BusinessActivityApi - functional programming interface
 * @export
 */
export const BusinessActivityApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BusinessActivityApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get all enums from BAM
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bamCodes(ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CodesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bamCodes(ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Contract invoice file donwload
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {string} invoiceNumber Invoice id from external system
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractInvoiceDownload(contractUid: string, invoiceNumber: string, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractInvoiceDownload(contractUid, invoiceNumber, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Contract invoices search
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {number} [pageNumber] Results page number
         * @param {number} [pageSize] Results page size
         * @param {string} [fulltext] Fulltext search string
         * @param {string} [invoicedFrom] Filter invoiced from date
         * @param {string} [invoicedTill] Filter invoiced till date
         * @param {string} [dueFrom] Filter due from date
         * @param {string} [dueTill] Filter due till date
         * @param {number} [amountFrom] Transaction amount from
         * @param {number} [amountTill] Transaction amount till
         * @param {string} [invoiceNo] Invoice number
         * @param {boolean} [isPaid] Invoice was payed
         * @param {Array<string>} [locationMasterId] Location ID from the BO system
         * @param {Array<string>} [productType] Product type
         * @param {Array<string>} [invoiceType] invoice type
         * @param {string} [sortBy] Set column for sorting
         * @param {boolean} [sortDesc] Describe if sort is descendinf
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractInvoices(contractUid: string, ngmCountry?: 'cz' | 'be', pageNumber?: number, pageSize?: number, fulltext?: string, invoicedFrom?: string, invoicedTill?: string, dueFrom?: string, dueTill?: string, amountFrom?: number, amountTill?: number, invoiceNo?: string, isPaid?: boolean, locationMasterId?: Array<string>, productType?: Array<string>, invoiceType?: Array<string>, sortBy?: string, sortDesc?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoicesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractInvoices(contractUid, ngmCountry, pageNumber, pageSize, fulltext, invoicedFrom, invoicedTill, dueFrom, dueTill, amountFrom, amountTill, invoiceNo, isPaid, locationMasterId, productType, invoiceType, sortBy, sortDesc, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create contract invoices bulk request
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {string} [invoicedFrom] Filter invoiced from date
         * @param {string} [invoicedTill] Filter invoiced till date
         * @param {string} [dueFrom] Filter due from date
         * @param {string} [dueTill] Filter due till date
         * @param {number} [amountFrom] Transaction amount from
         * @param {number} [amountTill] Transaction amount till
         * @param {boolean} [isPaid] Invoice was payed
         * @param {Array<string>} [locationMasterId] Location ID from the BO system
         * @param {Array<string>} [productType] Product type
         * @param {Array<string>} [invoiceType] invoice type
         * @param {Array<string>} [invoiceIdsOnly] Location ID from the BO system
         * @param {Array<string>} [invoiceIdsExcluded] Location ID from the BO system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractInvoicesCreateBulk(contractUid: string, ngmCountry?: 'cz' | 'be', invoicedFrom?: string, invoicedTill?: string, dueFrom?: string, dueTill?: string, amountFrom?: number, amountTill?: number, isPaid?: boolean, locationMasterId?: Array<string>, productType?: Array<string>, invoiceType?: Array<string>, invoiceIdsOnly?: Array<string>, invoiceIdsExcluded?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Bulk>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractInvoicesCreateBulk(contractUid, ngmCountry, invoicedFrom, invoicedTill, dueFrom, dueTill, amountFrom, amountTill, isPaid, locationMasterId, productType, invoiceType, invoiceIdsOnly, invoiceIdsExcluded, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create contract invoices export request
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {string} [fulltext] Fulltext search string
         * @param {string} [invoicedFrom] Filter invoiced from date
         * @param {string} [invoicedTill] Filter invoiced till date
         * @param {string} [dueFrom] Filter due from date
         * @param {string} [dueTill] Filter due till date
         * @param {number} [amountFrom] Transaction amount from
         * @param {number} [amountTill] Transaction amount till
         * @param {string} [invoiceNo] Invoice number
         * @param {boolean} [isPaid] Invoice was payed
         * @param {Array<string>} [locationMasterId] Location ID from the BO system
         * @param {Array<string>} [productType] Product type
         * @param {Array<string>} [invoiceType] invoice type
         * @param {string} [sortBy] Set column for sorting
         * @param {boolean} [sortDesc] Describe if sort is descendinf
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractInvoicesCreateExport(contractUid: string, ngmCountry?: 'cz' | 'be', fulltext?: string, invoicedFrom?: string, invoicedTill?: string, dueFrom?: string, dueTill?: string, amountFrom?: number, amountTill?: number, invoiceNo?: string, isPaid?: boolean, locationMasterId?: Array<string>, productType?: Array<string>, invoiceType?: Array<string>, sortBy?: string, sortDesc?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractInvoicesCreateExport(contractUid, ngmCountry, fulltext, invoicedFrom, invoicedTill, dueFrom, dueTill, amountFrom, amountTill, invoiceNo, isPaid, locationMasterId, productType, invoiceType, sortBy, sortDesc, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get contract invoices export file
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractInvoicesDownloadExport(contractUid: string, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractInvoicesDownloadExport(contractUid, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Contract packages search
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {number} [pageNumber] Results page number
         * @param {number} [pageSize] Results page size
         * @param {string} [fulltext] Fulltext search string
         * @param {string} [deliveryFrom] Filter invoiced from date
         * @param {string} [deliveryTill] Filter invoiced till date
         * @param {string} [dueFrom] Filter due from date
         * @param {string} [dueTill] Filter due till date
         * @param {string} [paymentFrom] Filter payment from date
         * @param {string} [paymentTill] Filter payment till date
         * @param {number} [amountFrom] Transaction amount from
         * @param {number} [amountTill] Transaction amount till
         * @param {Array<string>} [invoiceNo] Invoice number
         * @param {Array<string>} [packageNo] package number
         * @param {Array<string>} [locationMasterId] Location ID from the BO system
         * @param {string} [deliveryChannel] Delivery channel
         * @param {string} [sortBy] Set column for sorting
         * @param {boolean} [sortDesc] Describe if sort is descendinf
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractPackages(contractUid: string, ngmCountry?: 'cz' | 'be', pageNumber?: number, pageSize?: number, fulltext?: string, deliveryFrom?: string, deliveryTill?: string, dueFrom?: string, dueTill?: string, paymentFrom?: string, paymentTill?: string, amountFrom?: number, amountTill?: number, invoiceNo?: Array<string>, packageNo?: Array<string>, locationMasterId?: Array<string>, deliveryChannel?: string, sortBy?: string, sortDesc?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PackagesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractPackages(contractUid, ngmCountry, pageNumber, pageSize, fulltext, deliveryFrom, deliveryTill, dueFrom, dueTill, paymentFrom, paymentTill, amountFrom, amountTill, invoiceNo, packageNo, locationMasterId, deliveryChannel, sortBy, sortDesc, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create contract packages export request
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {string} [fulltext] Fulltext search string
         * @param {string} [deliveryFrom] Filter invoiced from date
         * @param {string} [deliveryTill] Filter invoiced till date
         * @param {string} [dueFrom] Filter due from date
         * @param {string} [dueTill] Filter due till date
         * @param {string} [paymentFrom] Filter payment from date
         * @param {string} [paymentTill] Filter payment till date
         * @param {number} [amountFrom] Transaction amount from
         * @param {number} [amountTill] Transaction amount till
         * @param {Array<string>} [invoiceNo] Invoice number
         * @param {Array<string>} [packageNo] package number
         * @param {Array<string>} [locationMasterId] Location ID from the BO system
         * @param {string} [deliveryChannel] Delivery channel
         * @param {string} [sortBy] Set column for sorting
         * @param {boolean} [sortDesc] Describe if sort is descendinf
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractPackagesCreateExport(contractUid: string, ngmCountry?: 'cz' | 'be', fulltext?: string, deliveryFrom?: string, deliveryTill?: string, dueFrom?: string, dueTill?: string, paymentFrom?: string, paymentTill?: string, amountFrom?: number, amountTill?: number, invoiceNo?: Array<string>, packageNo?: Array<string>, locationMasterId?: Array<string>, deliveryChannel?: string, sortBy?: string, sortDesc?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractPackagesCreateExport(contractUid, ngmCountry, fulltext, deliveryFrom, deliveryTill, dueFrom, dueTill, paymentFrom, paymentTill, amountFrom, amountTill, invoiceNo, packageNo, locationMasterId, deliveryChannel, sortBy, sortDesc, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get contract packages export file
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractPackagesDownloadExport(contractUid: string, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractPackagesDownloadExport(contractUid, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get list of active exports for contract and user
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractScheduledTasks(contractUid: string, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScheduledTaskListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractScheduledTasks(contractUid, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Contract transactions claim
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {string} transactionCode Master ID of the transaction. Also known as \&#39;internal ID\&#39;
         * @param {TransactionClaimRequest} transactionClaimRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractTransactionClaim(contractUid: string, transactionCode: string, transactionClaimRequest: TransactionClaimRequest, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionClaimResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractTransactionClaim(contractUid, transactionCode, transactionClaimRequest, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Contract transaction claimable amount
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {string} transactionCode Master ID of the transaction. Also known as \&#39;internal ID\&#39;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractTransactionClaimableAmount(contractUid: string, transactionCode: string, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionClaimableAmountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractTransactionClaimableAmount(contractUid, transactionCode, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Contract transactions search
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {number} [pageNumber] Results page number
         * @param {number} [pageSize] Results page size
         * @param {string} [fulltext] Fulltext search string
         * @param {string} [note] Filter note search string
         * @param {string} [createdFrom] Filter created from date
         * @param {string} [createdTill] Filter created till date
         * @param {number} [amountFrom] Transaction amount from
         * @param {number} [amountTill] Transaction amount till
         * @param {Array<string>} [invoiceNo] Invoice number
         * @param {Array<string>} [locationMasterId] Location ID from the BO system
         * @param {string} [orderMasterId] Order ID from the BO system
         * @param {string} [extPaymentId] Id of the original claim request from NGM.
         * @param {Array<string>} [terminalMasterId] POS Terminal ID from the BO system
         * @param {Array<string>} [physicalTerminalId] POS Terminal ID from the BO system
         * @param {Array<string>} [authCode] Authorization codes. Also known as \&#39;approval code\&#39;.
         * @param {Array<string>} [productCode] Product codes
         * @param {Array<string>} [transactionType] Transaction types
         * @param {Array<string>} [paymentMethod] Payment methods
         * @param {string} [sortBy] Set column for sorting
         * @param {Array<TransactionStatusEnum>} [status] Status of the transactions
         * @param {boolean} [sortDesc] Describe if sort is descendinf
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractTransactions(contractUid: string, ngmCountry?: 'cz' | 'be', pageNumber?: number, pageSize?: number, fulltext?: string, note?: string, createdFrom?: string, createdTill?: string, amountFrom?: number, amountTill?: number, invoiceNo?: Array<string>, locationMasterId?: Array<string>, orderMasterId?: string, extPaymentId?: string, terminalMasterId?: Array<string>, physicalTerminalId?: Array<string>, authCode?: Array<string>, productCode?: Array<string>, transactionType?: Array<string>, paymentMethod?: Array<string>, sortBy?: string, status?: Array<TransactionStatusEnum>, sortDesc?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractTransactions(contractUid, ngmCountry, pageNumber, pageSize, fulltext, note, createdFrom, createdTill, amountFrom, amountTill, invoiceNo, locationMasterId, orderMasterId, extPaymentId, terminalMasterId, physicalTerminalId, authCode, productCode, transactionType, paymentMethod, sortBy, status, sortDesc, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Contract transactions count
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {string} [note] Filter note search string
         * @param {string} [fulltext] Fulltext search string
         * @param {string} [createdFrom] Filter created from date
         * @param {string} [createdTill] Filter created till date
         * @param {number} [amountFrom] Transaction amount from
         * @param {number} [amountTill] Transaction amount till
         * @param {Array<string>} [invoiceNo] Invoice number
         * @param {Array<string>} [locationMasterId] Location ID from the BO system
         * @param {string} [orderMasterId] Order ID from the BO system
         * @param {Array<string>} [terminalMasterId] POS Terminal ID from the BO system
         * @param {Array<string>} [physicalTerminalId] Physical terminal ID from the BO system
         * @param {Array<string>} [authCode] Authorization codes. Also known as \&#39;approval code\&#39;.
         * @param {Array<string>} [productCode] Product codes
         * @param {Array<string>} [transactionType] Transaction types
         * @param {Array<string>} [paymentMethod] Payment methods
         * @param {Array<TransactionStatusEnum>} [status] Status of the transactions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractTransactionsCount(contractUid: string, ngmCountry?: 'cz' | 'be', note?: string, fulltext?: string, createdFrom?: string, createdTill?: string, amountFrom?: number, amountTill?: number, invoiceNo?: Array<string>, locationMasterId?: Array<string>, orderMasterId?: string, terminalMasterId?: Array<string>, physicalTerminalId?: Array<string>, authCode?: Array<string>, productCode?: Array<string>, transactionType?: Array<string>, paymentMethod?: Array<string>, status?: Array<TransactionStatusEnum>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractTransactionsCount(contractUid, ngmCountry, note, fulltext, createdFrom, createdTill, amountFrom, amountTill, invoiceNo, locationMasterId, orderMasterId, terminalMasterId, physicalTerminalId, authCode, productCode, transactionType, paymentMethod, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create contract transactions export request
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {string} [note] Filter note search string
         * @param {string} [createdFrom] Filter created from date
         * @param {string} [createdTill] Filter created till date
         * @param {number} [amountFrom] Transaction amount from
         * @param {number} [amountTill] Transaction amount till
         * @param {Array<string>} [invoiceNo] Invoice number
         * @param {Array<string>} [locationMasterId] Location ID from the BO system
         * @param {string} [orderMasterId] Order ID from the BO system
         * @param {Array<string>} [physicalTerminalId] POS Terminal ID from the BO system
         * @param {Array<string>} [terminalMasterId] POS Terminal ID from the BO system
         * @param {Array<string>} [authCode] Authorization codes. Also known as \&#39;approval code\&#39;.
         * @param {Array<string>} [productCode] Product codes
         * @param {Array<string>} [transactionType] Transaction types
         * @param {Array<string>} [paymentMethod] Payment methods
         * @param {string} [sortBy] Set column for sorting
         * @param {Array<TransactionStatusEnum>} [status] Status of the transactions
         * @param {boolean} [sortDesc] Describe if sort is descendinf
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractTransactionsCreateExport(contractUid: string, ngmCountry?: 'cz' | 'be', note?: string, createdFrom?: string, createdTill?: string, amountFrom?: number, amountTill?: number, invoiceNo?: Array<string>, locationMasterId?: Array<string>, orderMasterId?: string, physicalTerminalId?: Array<string>, terminalMasterId?: Array<string>, authCode?: Array<string>, productCode?: Array<string>, transactionType?: Array<string>, paymentMethod?: Array<string>, sortBy?: string, status?: Array<TransactionStatusEnum>, sortDesc?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractTransactionsCreateExport(contractUid, ngmCountry, note, createdFrom, createdTill, amountFrom, amountTill, invoiceNo, locationMasterId, orderMasterId, physicalTerminalId, terminalMasterId, authCode, productCode, transactionType, paymentMethod, sortBy, status, sortDesc, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get contract transactions export file
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractTransactionsDownloadExport(contractUid: string, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractTransactionsDownloadExport(contractUid, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Performance business report
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'year' | 'month'} displayedBy Choose view type
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {number} [pageNumber] Results page number
         * @param {number} [pageSize] Results page size
         * @param {Array<string>} [locationMasterId] Location ID from the BO system
         * @param {'year' | 'month'} [sortBy] Set column for sorting
         * @param {boolean} [sortDesc] Describe if sort is descending
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async performanceReport(contractUid: string, displayedBy: 'year' | 'month', ngmCountry?: 'cz' | 'be', pageNumber?: number, pageSize?: number, locationMasterId?: Array<string>, sortBy?: 'year' | 'month', sortDesc?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PerformanceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.performanceReport(contractUid, displayedBy, ngmCountry, pageNumber, pageSize, locationMasterId, sortBy, sortDesc, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create contract performance report export request
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'year' | 'month'} displayedBy Choose view type
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {Array<string>} [locationMasterId] Location ID from the BO system
         * @param {'year' | 'month'} [sortBy] Set column for sorting
         * @param {boolean} [sortDesc] Describe if sort is descending
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async performanceReportCreateExport(contractUid: string, displayedBy: 'year' | 'month', ngmCountry?: 'cz' | 'be', locationMasterId?: Array<string>, sortBy?: 'year' | 'month', sortDesc?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.performanceReportCreateExport(contractUid, displayedBy, ngmCountry, locationMasterId, sortBy, sortDesc, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get contract performance report export file
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async performanceReportDownloadExport(contractUid: string, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.performanceReportDownloadExport(contractUid, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Request for push the contract transactions list into the BAM, from specific date till oldest records in the database
         * @summary Submit request for historical contract transactions
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {string} [createdTill] Records created from date
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transactionsHistoryRequest(contractUid: string, createdTill?: string, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionsHistoryStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.transactionsHistoryRequest(contractUid, createdTill, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get status of the latest request to obtain historical transations from the BO and timestamp of the latest available record (related with the contract)
         * @summary Status of the latest request to historical transations
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transactionsHistoryState(contractUid: string, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionsHistoryStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.transactionsHistoryState(contractUid, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BusinessActivityApi - factory interface
 * @export
 */
export const BusinessActivityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BusinessActivityApiFp(configuration)
    return {
        /**
         * 
         * @summary Get all enums from BAM
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bamCodes(ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<CodesResponse> {
            return localVarFp.bamCodes(ngmCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Contract invoice file donwload
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {string} invoiceNumber Invoice id from external system
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractInvoiceDownload(contractUid: string, invoiceNumber: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<void> {
            return localVarFp.contractInvoiceDownload(contractUid, invoiceNumber, ngmCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Contract invoices search
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {number} [pageNumber] Results page number
         * @param {number} [pageSize] Results page size
         * @param {string} [fulltext] Fulltext search string
         * @param {string} [invoicedFrom] Filter invoiced from date
         * @param {string} [invoicedTill] Filter invoiced till date
         * @param {string} [dueFrom] Filter due from date
         * @param {string} [dueTill] Filter due till date
         * @param {number} [amountFrom] Transaction amount from
         * @param {number} [amountTill] Transaction amount till
         * @param {string} [invoiceNo] Invoice number
         * @param {boolean} [isPaid] Invoice was payed
         * @param {Array<string>} [locationMasterId] Location ID from the BO system
         * @param {Array<string>} [productType] Product type
         * @param {Array<string>} [invoiceType] invoice type
         * @param {string} [sortBy] Set column for sorting
         * @param {boolean} [sortDesc] Describe if sort is descendinf
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractInvoices(contractUid: string, ngmCountry?: 'cz' | 'be', pageNumber?: number, pageSize?: number, fulltext?: string, invoicedFrom?: string, invoicedTill?: string, dueFrom?: string, dueTill?: string, amountFrom?: number, amountTill?: number, invoiceNo?: string, isPaid?: boolean, locationMasterId?: Array<string>, productType?: Array<string>, invoiceType?: Array<string>, sortBy?: string, sortDesc?: boolean, options?: any): AxiosPromise<InvoicesResponse> {
            return localVarFp.contractInvoices(contractUid, ngmCountry, pageNumber, pageSize, fulltext, invoicedFrom, invoicedTill, dueFrom, dueTill, amountFrom, amountTill, invoiceNo, isPaid, locationMasterId, productType, invoiceType, sortBy, sortDesc, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create contract invoices bulk request
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {string} [invoicedFrom] Filter invoiced from date
         * @param {string} [invoicedTill] Filter invoiced till date
         * @param {string} [dueFrom] Filter due from date
         * @param {string} [dueTill] Filter due till date
         * @param {number} [amountFrom] Transaction amount from
         * @param {number} [amountTill] Transaction amount till
         * @param {boolean} [isPaid] Invoice was payed
         * @param {Array<string>} [locationMasterId] Location ID from the BO system
         * @param {Array<string>} [productType] Product type
         * @param {Array<string>} [invoiceType] invoice type
         * @param {Array<string>} [invoiceIdsOnly] Location ID from the BO system
         * @param {Array<string>} [invoiceIdsExcluded] Location ID from the BO system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractInvoicesCreateBulk(contractUid: string, ngmCountry?: 'cz' | 'be', invoicedFrom?: string, invoicedTill?: string, dueFrom?: string, dueTill?: string, amountFrom?: number, amountTill?: number, isPaid?: boolean, locationMasterId?: Array<string>, productType?: Array<string>, invoiceType?: Array<string>, invoiceIdsOnly?: Array<string>, invoiceIdsExcluded?: Array<string>, options?: any): AxiosPromise<Bulk> {
            return localVarFp.contractInvoicesCreateBulk(contractUid, ngmCountry, invoicedFrom, invoicedTill, dueFrom, dueTill, amountFrom, amountTill, isPaid, locationMasterId, productType, invoiceType, invoiceIdsOnly, invoiceIdsExcluded, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create contract invoices export request
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {string} [fulltext] Fulltext search string
         * @param {string} [invoicedFrom] Filter invoiced from date
         * @param {string} [invoicedTill] Filter invoiced till date
         * @param {string} [dueFrom] Filter due from date
         * @param {string} [dueTill] Filter due till date
         * @param {number} [amountFrom] Transaction amount from
         * @param {number} [amountTill] Transaction amount till
         * @param {string} [invoiceNo] Invoice number
         * @param {boolean} [isPaid] Invoice was payed
         * @param {Array<string>} [locationMasterId] Location ID from the BO system
         * @param {Array<string>} [productType] Product type
         * @param {Array<string>} [invoiceType] invoice type
         * @param {string} [sortBy] Set column for sorting
         * @param {boolean} [sortDesc] Describe if sort is descendinf
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractInvoicesCreateExport(contractUid: string, ngmCountry?: 'cz' | 'be', fulltext?: string, invoicedFrom?: string, invoicedTill?: string, dueFrom?: string, dueTill?: string, amountFrom?: number, amountTill?: number, invoiceNo?: string, isPaid?: boolean, locationMasterId?: Array<string>, productType?: Array<string>, invoiceType?: Array<string>, sortBy?: string, sortDesc?: boolean, options?: any): AxiosPromise<ExportResponse> {
            return localVarFp.contractInvoicesCreateExport(contractUid, ngmCountry, fulltext, invoicedFrom, invoicedTill, dueFrom, dueTill, amountFrom, amountTill, invoiceNo, isPaid, locationMasterId, productType, invoiceType, sortBy, sortDesc, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get contract invoices export file
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractInvoicesDownloadExport(contractUid: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<void> {
            return localVarFp.contractInvoicesDownloadExport(contractUid, ngmCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Contract packages search
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {number} [pageNumber] Results page number
         * @param {number} [pageSize] Results page size
         * @param {string} [fulltext] Fulltext search string
         * @param {string} [deliveryFrom] Filter invoiced from date
         * @param {string} [deliveryTill] Filter invoiced till date
         * @param {string} [dueFrom] Filter due from date
         * @param {string} [dueTill] Filter due till date
         * @param {string} [paymentFrom] Filter payment from date
         * @param {string} [paymentTill] Filter payment till date
         * @param {number} [amountFrom] Transaction amount from
         * @param {number} [amountTill] Transaction amount till
         * @param {Array<string>} [invoiceNo] Invoice number
         * @param {Array<string>} [packageNo] package number
         * @param {Array<string>} [locationMasterId] Location ID from the BO system
         * @param {string} [deliveryChannel] Delivery channel
         * @param {string} [sortBy] Set column for sorting
         * @param {boolean} [sortDesc] Describe if sort is descendinf
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractPackages(contractUid: string, ngmCountry?: 'cz' | 'be', pageNumber?: number, pageSize?: number, fulltext?: string, deliveryFrom?: string, deliveryTill?: string, dueFrom?: string, dueTill?: string, paymentFrom?: string, paymentTill?: string, amountFrom?: number, amountTill?: number, invoiceNo?: Array<string>, packageNo?: Array<string>, locationMasterId?: Array<string>, deliveryChannel?: string, sortBy?: string, sortDesc?: boolean, options?: any): AxiosPromise<PackagesResponse> {
            return localVarFp.contractPackages(contractUid, ngmCountry, pageNumber, pageSize, fulltext, deliveryFrom, deliveryTill, dueFrom, dueTill, paymentFrom, paymentTill, amountFrom, amountTill, invoiceNo, packageNo, locationMasterId, deliveryChannel, sortBy, sortDesc, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create contract packages export request
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {string} [fulltext] Fulltext search string
         * @param {string} [deliveryFrom] Filter invoiced from date
         * @param {string} [deliveryTill] Filter invoiced till date
         * @param {string} [dueFrom] Filter due from date
         * @param {string} [dueTill] Filter due till date
         * @param {string} [paymentFrom] Filter payment from date
         * @param {string} [paymentTill] Filter payment till date
         * @param {number} [amountFrom] Transaction amount from
         * @param {number} [amountTill] Transaction amount till
         * @param {Array<string>} [invoiceNo] Invoice number
         * @param {Array<string>} [packageNo] package number
         * @param {Array<string>} [locationMasterId] Location ID from the BO system
         * @param {string} [deliveryChannel] Delivery channel
         * @param {string} [sortBy] Set column for sorting
         * @param {boolean} [sortDesc] Describe if sort is descendinf
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractPackagesCreateExport(contractUid: string, ngmCountry?: 'cz' | 'be', fulltext?: string, deliveryFrom?: string, deliveryTill?: string, dueFrom?: string, dueTill?: string, paymentFrom?: string, paymentTill?: string, amountFrom?: number, amountTill?: number, invoiceNo?: Array<string>, packageNo?: Array<string>, locationMasterId?: Array<string>, deliveryChannel?: string, sortBy?: string, sortDesc?: boolean, options?: any): AxiosPromise<ExportResponse> {
            return localVarFp.contractPackagesCreateExport(contractUid, ngmCountry, fulltext, deliveryFrom, deliveryTill, dueFrom, dueTill, paymentFrom, paymentTill, amountFrom, amountTill, invoiceNo, packageNo, locationMasterId, deliveryChannel, sortBy, sortDesc, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get contract packages export file
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractPackagesDownloadExport(contractUid: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<void> {
            return localVarFp.contractPackagesDownloadExport(contractUid, ngmCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of active exports for contract and user
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractScheduledTasks(contractUid: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<ScheduledTaskListResponse> {
            return localVarFp.contractScheduledTasks(contractUid, ngmCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Contract transactions claim
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {string} transactionCode Master ID of the transaction. Also known as \&#39;internal ID\&#39;
         * @param {TransactionClaimRequest} transactionClaimRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractTransactionClaim(contractUid: string, transactionCode: string, transactionClaimRequest: TransactionClaimRequest, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<TransactionClaimResponse> {
            return localVarFp.contractTransactionClaim(contractUid, transactionCode, transactionClaimRequest, ngmCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Contract transaction claimable amount
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {string} transactionCode Master ID of the transaction. Also known as \&#39;internal ID\&#39;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractTransactionClaimableAmount(contractUid: string, transactionCode: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<TransactionClaimableAmountResponse> {
            return localVarFp.contractTransactionClaimableAmount(contractUid, transactionCode, ngmCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Contract transactions search
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {number} [pageNumber] Results page number
         * @param {number} [pageSize] Results page size
         * @param {string} [fulltext] Fulltext search string
         * @param {string} [note] Filter note search string
         * @param {string} [createdFrom] Filter created from date
         * @param {string} [createdTill] Filter created till date
         * @param {number} [amountFrom] Transaction amount from
         * @param {number} [amountTill] Transaction amount till
         * @param {Array<string>} [invoiceNo] Invoice number
         * @param {Array<string>} [locationMasterId] Location ID from the BO system
         * @param {string} [orderMasterId] Order ID from the BO system
         * @param {string} [extPaymentId] Id of the original claim request from NGM.
         * @param {Array<string>} [terminalMasterId] POS Terminal ID from the BO system
         * @param {Array<string>} [physicalTerminalId] POS Terminal ID from the BO system
         * @param {Array<string>} [authCode] Authorization codes. Also known as \&#39;approval code\&#39;.
         * @param {Array<string>} [productCode] Product codes
         * @param {Array<string>} [transactionType] Transaction types
         * @param {Array<string>} [paymentMethod] Payment methods
         * @param {string} [sortBy] Set column for sorting
         * @param {Array<TransactionStatusEnum>} [status] Status of the transactions
         * @param {boolean} [sortDesc] Describe if sort is descendinf
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractTransactions(contractUid: string, ngmCountry?: 'cz' | 'be', pageNumber?: number, pageSize?: number, fulltext?: string, note?: string, createdFrom?: string, createdTill?: string, amountFrom?: number, amountTill?: number, invoiceNo?: Array<string>, locationMasterId?: Array<string>, orderMasterId?: string, extPaymentId?: string, terminalMasterId?: Array<string>, physicalTerminalId?: Array<string>, authCode?: Array<string>, productCode?: Array<string>, transactionType?: Array<string>, paymentMethod?: Array<string>, sortBy?: string, status?: Array<TransactionStatusEnum>, sortDesc?: boolean, options?: any): AxiosPromise<TransactionsResponse> {
            return localVarFp.contractTransactions(contractUid, ngmCountry, pageNumber, pageSize, fulltext, note, createdFrom, createdTill, amountFrom, amountTill, invoiceNo, locationMasterId, orderMasterId, extPaymentId, terminalMasterId, physicalTerminalId, authCode, productCode, transactionType, paymentMethod, sortBy, status, sortDesc, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Contract transactions count
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {string} [note] Filter note search string
         * @param {string} [fulltext] Fulltext search string
         * @param {string} [createdFrom] Filter created from date
         * @param {string} [createdTill] Filter created till date
         * @param {number} [amountFrom] Transaction amount from
         * @param {number} [amountTill] Transaction amount till
         * @param {Array<string>} [invoiceNo] Invoice number
         * @param {Array<string>} [locationMasterId] Location ID from the BO system
         * @param {string} [orderMasterId] Order ID from the BO system
         * @param {Array<string>} [terminalMasterId] POS Terminal ID from the BO system
         * @param {Array<string>} [physicalTerminalId] Physical terminal ID from the BO system
         * @param {Array<string>} [authCode] Authorization codes. Also known as \&#39;approval code\&#39;.
         * @param {Array<string>} [productCode] Product codes
         * @param {Array<string>} [transactionType] Transaction types
         * @param {Array<string>} [paymentMethod] Payment methods
         * @param {Array<TransactionStatusEnum>} [status] Status of the transactions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractTransactionsCount(contractUid: string, ngmCountry?: 'cz' | 'be', note?: string, fulltext?: string, createdFrom?: string, createdTill?: string, amountFrom?: number, amountTill?: number, invoiceNo?: Array<string>, locationMasterId?: Array<string>, orderMasterId?: string, terminalMasterId?: Array<string>, physicalTerminalId?: Array<string>, authCode?: Array<string>, productCode?: Array<string>, transactionType?: Array<string>, paymentMethod?: Array<string>, status?: Array<TransactionStatusEnum>, options?: any): AxiosPromise<CountResponse> {
            return localVarFp.contractTransactionsCount(contractUid, ngmCountry, note, fulltext, createdFrom, createdTill, amountFrom, amountTill, invoiceNo, locationMasterId, orderMasterId, terminalMasterId, physicalTerminalId, authCode, productCode, transactionType, paymentMethod, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create contract transactions export request
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {string} [note] Filter note search string
         * @param {string} [createdFrom] Filter created from date
         * @param {string} [createdTill] Filter created till date
         * @param {number} [amountFrom] Transaction amount from
         * @param {number} [amountTill] Transaction amount till
         * @param {Array<string>} [invoiceNo] Invoice number
         * @param {Array<string>} [locationMasterId] Location ID from the BO system
         * @param {string} [orderMasterId] Order ID from the BO system
         * @param {Array<string>} [physicalTerminalId] POS Terminal ID from the BO system
         * @param {Array<string>} [terminalMasterId] POS Terminal ID from the BO system
         * @param {Array<string>} [authCode] Authorization codes. Also known as \&#39;approval code\&#39;.
         * @param {Array<string>} [productCode] Product codes
         * @param {Array<string>} [transactionType] Transaction types
         * @param {Array<string>} [paymentMethod] Payment methods
         * @param {string} [sortBy] Set column for sorting
         * @param {Array<TransactionStatusEnum>} [status] Status of the transactions
         * @param {boolean} [sortDesc] Describe if sort is descendinf
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractTransactionsCreateExport(contractUid: string, ngmCountry?: 'cz' | 'be', note?: string, createdFrom?: string, createdTill?: string, amountFrom?: number, amountTill?: number, invoiceNo?: Array<string>, locationMasterId?: Array<string>, orderMasterId?: string, physicalTerminalId?: Array<string>, terminalMasterId?: Array<string>, authCode?: Array<string>, productCode?: Array<string>, transactionType?: Array<string>, paymentMethod?: Array<string>, sortBy?: string, status?: Array<TransactionStatusEnum>, sortDesc?: boolean, options?: any): AxiosPromise<ExportResponse> {
            return localVarFp.contractTransactionsCreateExport(contractUid, ngmCountry, note, createdFrom, createdTill, amountFrom, amountTill, invoiceNo, locationMasterId, orderMasterId, physicalTerminalId, terminalMasterId, authCode, productCode, transactionType, paymentMethod, sortBy, status, sortDesc, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get contract transactions export file
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractTransactionsDownloadExport(contractUid: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<void> {
            return localVarFp.contractTransactionsDownloadExport(contractUid, ngmCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Performance business report
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'year' | 'month'} displayedBy Choose view type
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {number} [pageNumber] Results page number
         * @param {number} [pageSize] Results page size
         * @param {Array<string>} [locationMasterId] Location ID from the BO system
         * @param {'year' | 'month'} [sortBy] Set column for sorting
         * @param {boolean} [sortDesc] Describe if sort is descending
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performanceReport(contractUid: string, displayedBy: 'year' | 'month', ngmCountry?: 'cz' | 'be', pageNumber?: number, pageSize?: number, locationMasterId?: Array<string>, sortBy?: 'year' | 'month', sortDesc?: boolean, options?: any): AxiosPromise<PerformanceResponse> {
            return localVarFp.performanceReport(contractUid, displayedBy, ngmCountry, pageNumber, pageSize, locationMasterId, sortBy, sortDesc, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create contract performance report export request
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'year' | 'month'} displayedBy Choose view type
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {Array<string>} [locationMasterId] Location ID from the BO system
         * @param {'year' | 'month'} [sortBy] Set column for sorting
         * @param {boolean} [sortDesc] Describe if sort is descending
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performanceReportCreateExport(contractUid: string, displayedBy: 'year' | 'month', ngmCountry?: 'cz' | 'be', locationMasterId?: Array<string>, sortBy?: 'year' | 'month', sortDesc?: boolean, options?: any): AxiosPromise<ExportResponse> {
            return localVarFp.performanceReportCreateExport(contractUid, displayedBy, ngmCountry, locationMasterId, sortBy, sortDesc, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get contract performance report export file
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performanceReportDownloadExport(contractUid: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<void> {
            return localVarFp.performanceReportDownloadExport(contractUid, ngmCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * Request for push the contract transactions list into the BAM, from specific date till oldest records in the database
         * @summary Submit request for historical contract transactions
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {string} [createdTill] Records created from date
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionsHistoryRequest(contractUid: string, createdTill?: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<TransactionsHistoryStatusResponse> {
            return localVarFp.transactionsHistoryRequest(contractUid, createdTill, ngmCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * Get status of the latest request to obtain historical transations from the BO and timestamp of the latest available record (related with the contract)
         * @summary Status of the latest request to historical transations
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionsHistoryState(contractUid: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<TransactionsHistoryStatusResponse> {
            return localVarFp.transactionsHistoryState(contractUid, ngmCountry, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BusinessActivityApi - interface
 * @export
 * @interface BusinessActivityApi
 */
export interface BusinessActivityApiInterface {
    /**
     * 
     * @summary Get all enums from BAM
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessActivityApiInterface
     */
    bamCodes(ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<CodesResponse>;

    /**
     * 
     * @summary Contract invoice file donwload
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {string} invoiceNumber Invoice id from external system
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessActivityApiInterface
     */
    contractInvoiceDownload(contractUid: string, invoiceNumber: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<void>;

    /**
     * 
     * @summary Contract invoices search
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {number} [pageNumber] Results page number
     * @param {number} [pageSize] Results page size
     * @param {string} [fulltext] Fulltext search string
     * @param {string} [invoicedFrom] Filter invoiced from date
     * @param {string} [invoicedTill] Filter invoiced till date
     * @param {string} [dueFrom] Filter due from date
     * @param {string} [dueTill] Filter due till date
     * @param {number} [amountFrom] Transaction amount from
     * @param {number} [amountTill] Transaction amount till
     * @param {string} [invoiceNo] Invoice number
     * @param {boolean} [isPaid] Invoice was payed
     * @param {Array<string>} [locationMasterId] Location ID from the BO system
     * @param {Array<string>} [productType] Product type
     * @param {Array<string>} [invoiceType] invoice type
     * @param {string} [sortBy] Set column for sorting
     * @param {boolean} [sortDesc] Describe if sort is descendinf
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessActivityApiInterface
     */
    contractInvoices(contractUid: string, ngmCountry?: 'cz' | 'be', pageNumber?: number, pageSize?: number, fulltext?: string, invoicedFrom?: string, invoicedTill?: string, dueFrom?: string, dueTill?: string, amountFrom?: number, amountTill?: number, invoiceNo?: string, isPaid?: boolean, locationMasterId?: Array<string>, productType?: Array<string>, invoiceType?: Array<string>, sortBy?: string, sortDesc?: boolean, options?: any): AxiosPromise<InvoicesResponse>;

    /**
     * 
     * @summary Create contract invoices bulk request
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {string} [invoicedFrom] Filter invoiced from date
     * @param {string} [invoicedTill] Filter invoiced till date
     * @param {string} [dueFrom] Filter due from date
     * @param {string} [dueTill] Filter due till date
     * @param {number} [amountFrom] Transaction amount from
     * @param {number} [amountTill] Transaction amount till
     * @param {boolean} [isPaid] Invoice was payed
     * @param {Array<string>} [locationMasterId] Location ID from the BO system
     * @param {Array<string>} [productType] Product type
     * @param {Array<string>} [invoiceType] invoice type
     * @param {Array<string>} [invoiceIdsOnly] Location ID from the BO system
     * @param {Array<string>} [invoiceIdsExcluded] Location ID from the BO system
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessActivityApiInterface
     */
    contractInvoicesCreateBulk(contractUid: string, ngmCountry?: 'cz' | 'be', invoicedFrom?: string, invoicedTill?: string, dueFrom?: string, dueTill?: string, amountFrom?: number, amountTill?: number, isPaid?: boolean, locationMasterId?: Array<string>, productType?: Array<string>, invoiceType?: Array<string>, invoiceIdsOnly?: Array<string>, invoiceIdsExcluded?: Array<string>, options?: any): AxiosPromise<Bulk>;

    /**
     * 
     * @summary Create contract invoices export request
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {string} [fulltext] Fulltext search string
     * @param {string} [invoicedFrom] Filter invoiced from date
     * @param {string} [invoicedTill] Filter invoiced till date
     * @param {string} [dueFrom] Filter due from date
     * @param {string} [dueTill] Filter due till date
     * @param {number} [amountFrom] Transaction amount from
     * @param {number} [amountTill] Transaction amount till
     * @param {string} [invoiceNo] Invoice number
     * @param {boolean} [isPaid] Invoice was payed
     * @param {Array<string>} [locationMasterId] Location ID from the BO system
     * @param {Array<string>} [productType] Product type
     * @param {Array<string>} [invoiceType] invoice type
     * @param {string} [sortBy] Set column for sorting
     * @param {boolean} [sortDesc] Describe if sort is descendinf
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessActivityApiInterface
     */
    contractInvoicesCreateExport(contractUid: string, ngmCountry?: 'cz' | 'be', fulltext?: string, invoicedFrom?: string, invoicedTill?: string, dueFrom?: string, dueTill?: string, amountFrom?: number, amountTill?: number, invoiceNo?: string, isPaid?: boolean, locationMasterId?: Array<string>, productType?: Array<string>, invoiceType?: Array<string>, sortBy?: string, sortDesc?: boolean, options?: any): AxiosPromise<ExportResponse>;

    /**
     * 
     * @summary Get contract invoices export file
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessActivityApiInterface
     */
    contractInvoicesDownloadExport(contractUid: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<void>;

    /**
     * 
     * @summary Contract packages search
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {number} [pageNumber] Results page number
     * @param {number} [pageSize] Results page size
     * @param {string} [fulltext] Fulltext search string
     * @param {string} [deliveryFrom] Filter invoiced from date
     * @param {string} [deliveryTill] Filter invoiced till date
     * @param {string} [dueFrom] Filter due from date
     * @param {string} [dueTill] Filter due till date
     * @param {string} [paymentFrom] Filter payment from date
     * @param {string} [paymentTill] Filter payment till date
     * @param {number} [amountFrom] Transaction amount from
     * @param {number} [amountTill] Transaction amount till
     * @param {Array<string>} [invoiceNo] Invoice number
     * @param {Array<string>} [packageNo] package number
     * @param {Array<string>} [locationMasterId] Location ID from the BO system
     * @param {string} [deliveryChannel] Delivery channel
     * @param {string} [sortBy] Set column for sorting
     * @param {boolean} [sortDesc] Describe if sort is descendinf
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessActivityApiInterface
     */
    contractPackages(contractUid: string, ngmCountry?: 'cz' | 'be', pageNumber?: number, pageSize?: number, fulltext?: string, deliveryFrom?: string, deliveryTill?: string, dueFrom?: string, dueTill?: string, paymentFrom?: string, paymentTill?: string, amountFrom?: number, amountTill?: number, invoiceNo?: Array<string>, packageNo?: Array<string>, locationMasterId?: Array<string>, deliveryChannel?: string, sortBy?: string, sortDesc?: boolean, options?: any): AxiosPromise<PackagesResponse>;

    /**
     * 
     * @summary Create contract packages export request
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {string} [fulltext] Fulltext search string
     * @param {string} [deliveryFrom] Filter invoiced from date
     * @param {string} [deliveryTill] Filter invoiced till date
     * @param {string} [dueFrom] Filter due from date
     * @param {string} [dueTill] Filter due till date
     * @param {string} [paymentFrom] Filter payment from date
     * @param {string} [paymentTill] Filter payment till date
     * @param {number} [amountFrom] Transaction amount from
     * @param {number} [amountTill] Transaction amount till
     * @param {Array<string>} [invoiceNo] Invoice number
     * @param {Array<string>} [packageNo] package number
     * @param {Array<string>} [locationMasterId] Location ID from the BO system
     * @param {string} [deliveryChannel] Delivery channel
     * @param {string} [sortBy] Set column for sorting
     * @param {boolean} [sortDesc] Describe if sort is descendinf
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessActivityApiInterface
     */
    contractPackagesCreateExport(contractUid: string, ngmCountry?: 'cz' | 'be', fulltext?: string, deliveryFrom?: string, deliveryTill?: string, dueFrom?: string, dueTill?: string, paymentFrom?: string, paymentTill?: string, amountFrom?: number, amountTill?: number, invoiceNo?: Array<string>, packageNo?: Array<string>, locationMasterId?: Array<string>, deliveryChannel?: string, sortBy?: string, sortDesc?: boolean, options?: any): AxiosPromise<ExportResponse>;

    /**
     * 
     * @summary Get contract packages export file
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessActivityApiInterface
     */
    contractPackagesDownloadExport(contractUid: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<void>;

    /**
     * 
     * @summary Get list of active exports for contract and user
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessActivityApiInterface
     */
    contractScheduledTasks(contractUid: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<ScheduledTaskListResponse>;

    /**
     * 
     * @summary Contract transactions claim
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {string} transactionCode Master ID of the transaction. Also known as \&#39;internal ID\&#39;
     * @param {TransactionClaimRequest} transactionClaimRequest 
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessActivityApiInterface
     */
    contractTransactionClaim(contractUid: string, transactionCode: string, transactionClaimRequest: TransactionClaimRequest, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<TransactionClaimResponse>;

    /**
     * 
     * @summary Contract transaction claimable amount
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {string} transactionCode Master ID of the transaction. Also known as \&#39;internal ID\&#39;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessActivityApiInterface
     */
    contractTransactionClaimableAmount(contractUid: string, transactionCode: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<TransactionClaimableAmountResponse>;

    /**
     * 
     * @summary Contract transactions search
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {number} [pageNumber] Results page number
     * @param {number} [pageSize] Results page size
     * @param {string} [fulltext] Fulltext search string
     * @param {string} [note] Filter note search string
     * @param {string} [createdFrom] Filter created from date
     * @param {string} [createdTill] Filter created till date
     * @param {number} [amountFrom] Transaction amount from
     * @param {number} [amountTill] Transaction amount till
     * @param {Array<string>} [invoiceNo] Invoice number
     * @param {Array<string>} [locationMasterId] Location ID from the BO system
     * @param {string} [orderMasterId] Order ID from the BO system
     * @param {string} [extPaymentId] Id of the original claim request from NGM.
     * @param {Array<string>} [terminalMasterId] POS Terminal ID from the BO system
     * @param {Array<string>} [physicalTerminalId] POS Terminal ID from the BO system
     * @param {Array<string>} [authCode] Authorization codes. Also known as \&#39;approval code\&#39;.
     * @param {Array<string>} [productCode] Product codes
     * @param {Array<string>} [transactionType] Transaction types
     * @param {Array<string>} [paymentMethod] Payment methods
     * @param {string} [sortBy] Set column for sorting
     * @param {Array<TransactionStatusEnum>} [status] Status of the transactions
     * @param {boolean} [sortDesc] Describe if sort is descendinf
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessActivityApiInterface
     */
    contractTransactions(contractUid: string, ngmCountry?: 'cz' | 'be', pageNumber?: number, pageSize?: number, fulltext?: string, note?: string, createdFrom?: string, createdTill?: string, amountFrom?: number, amountTill?: number, invoiceNo?: Array<string>, locationMasterId?: Array<string>, orderMasterId?: string, extPaymentId?: string, terminalMasterId?: Array<string>, physicalTerminalId?: Array<string>, authCode?: Array<string>, productCode?: Array<string>, transactionType?: Array<string>, paymentMethod?: Array<string>, sortBy?: string, status?: Array<TransactionStatusEnum>, sortDesc?: boolean, options?: any): AxiosPromise<TransactionsResponse>;

    /**
     * 
     * @summary Contract transactions count
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {string} [note] Filter note search string
     * @param {string} [fulltext] Fulltext search string
     * @param {string} [createdFrom] Filter created from date
     * @param {string} [createdTill] Filter created till date
     * @param {number} [amountFrom] Transaction amount from
     * @param {number} [amountTill] Transaction amount till
     * @param {Array<string>} [invoiceNo] Invoice number
     * @param {Array<string>} [locationMasterId] Location ID from the BO system
     * @param {string} [orderMasterId] Order ID from the BO system
     * @param {Array<string>} [terminalMasterId] POS Terminal ID from the BO system
     * @param {Array<string>} [physicalTerminalId] Physical terminal ID from the BO system
     * @param {Array<string>} [authCode] Authorization codes. Also known as \&#39;approval code\&#39;.
     * @param {Array<string>} [productCode] Product codes
     * @param {Array<string>} [transactionType] Transaction types
     * @param {Array<string>} [paymentMethod] Payment methods
     * @param {Array<TransactionStatusEnum>} [status] Status of the transactions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessActivityApiInterface
     */
    contractTransactionsCount(contractUid: string, ngmCountry?: 'cz' | 'be', note?: string, fulltext?: string, createdFrom?: string, createdTill?: string, amountFrom?: number, amountTill?: number, invoiceNo?: Array<string>, locationMasterId?: Array<string>, orderMasterId?: string, terminalMasterId?: Array<string>, physicalTerminalId?: Array<string>, authCode?: Array<string>, productCode?: Array<string>, transactionType?: Array<string>, paymentMethod?: Array<string>, status?: Array<TransactionStatusEnum>, options?: any): AxiosPromise<CountResponse>;

    /**
     * 
     * @summary Create contract transactions export request
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {string} [note] Filter note search string
     * @param {string} [createdFrom] Filter created from date
     * @param {string} [createdTill] Filter created till date
     * @param {number} [amountFrom] Transaction amount from
     * @param {number} [amountTill] Transaction amount till
     * @param {Array<string>} [invoiceNo] Invoice number
     * @param {Array<string>} [locationMasterId] Location ID from the BO system
     * @param {string} [orderMasterId] Order ID from the BO system
     * @param {Array<string>} [physicalTerminalId] POS Terminal ID from the BO system
     * @param {Array<string>} [terminalMasterId] POS Terminal ID from the BO system
     * @param {Array<string>} [authCode] Authorization codes. Also known as \&#39;approval code\&#39;.
     * @param {Array<string>} [productCode] Product codes
     * @param {Array<string>} [transactionType] Transaction types
     * @param {Array<string>} [paymentMethod] Payment methods
     * @param {string} [sortBy] Set column for sorting
     * @param {Array<TransactionStatusEnum>} [status] Status of the transactions
     * @param {boolean} [sortDesc] Describe if sort is descendinf
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessActivityApiInterface
     */
    contractTransactionsCreateExport(contractUid: string, ngmCountry?: 'cz' | 'be', note?: string, createdFrom?: string, createdTill?: string, amountFrom?: number, amountTill?: number, invoiceNo?: Array<string>, locationMasterId?: Array<string>, orderMasterId?: string, physicalTerminalId?: Array<string>, terminalMasterId?: Array<string>, authCode?: Array<string>, productCode?: Array<string>, transactionType?: Array<string>, paymentMethod?: Array<string>, sortBy?: string, status?: Array<TransactionStatusEnum>, sortDesc?: boolean, options?: any): AxiosPromise<ExportResponse>;

    /**
     * 
     * @summary Get contract transactions export file
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessActivityApiInterface
     */
    contractTransactionsDownloadExport(contractUid: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<void>;

    /**
     * 
     * @summary Performance business report
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {'year' | 'month'} displayedBy Choose view type
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {number} [pageNumber] Results page number
     * @param {number} [pageSize] Results page size
     * @param {Array<string>} [locationMasterId] Location ID from the BO system
     * @param {'year' | 'month'} [sortBy] Set column for sorting
     * @param {boolean} [sortDesc] Describe if sort is descending
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessActivityApiInterface
     */
    performanceReport(contractUid: string, displayedBy: 'year' | 'month', ngmCountry?: 'cz' | 'be', pageNumber?: number, pageSize?: number, locationMasterId?: Array<string>, sortBy?: 'year' | 'month', sortDesc?: boolean, options?: any): AxiosPromise<PerformanceResponse>;

    /**
     * 
     * @summary Create contract performance report export request
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {'year' | 'month'} displayedBy Choose view type
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {Array<string>} [locationMasterId] Location ID from the BO system
     * @param {'year' | 'month'} [sortBy] Set column for sorting
     * @param {boolean} [sortDesc] Describe if sort is descending
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessActivityApiInterface
     */
    performanceReportCreateExport(contractUid: string, displayedBy: 'year' | 'month', ngmCountry?: 'cz' | 'be', locationMasterId?: Array<string>, sortBy?: 'year' | 'month', sortDesc?: boolean, options?: any): AxiosPromise<ExportResponse>;

    /**
     * 
     * @summary Get contract performance report export file
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessActivityApiInterface
     */
    performanceReportDownloadExport(contractUid: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<void>;

    /**
     * Request for push the contract transactions list into the BAM, from specific date till oldest records in the database
     * @summary Submit request for historical contract transactions
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {string} [createdTill] Records created from date
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessActivityApiInterface
     */
    transactionsHistoryRequest(contractUid: string, createdTill?: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<TransactionsHistoryStatusResponse>;

    /**
     * Get status of the latest request to obtain historical transations from the BO and timestamp of the latest available record (related with the contract)
     * @summary Status of the latest request to historical transations
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessActivityApiInterface
     */
    transactionsHistoryState(contractUid: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<TransactionsHistoryStatusResponse>;

}

/**
 * BusinessActivityApi - object-oriented interface
 * @export
 * @class BusinessActivityApi
 * @extends {BaseAPI}
 */
export class BusinessActivityApi extends BaseAPI implements BusinessActivityApiInterface {
    /**
     * 
     * @summary Get all enums from BAM
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessActivityApi
     */
    public bamCodes(ngmCountry?: 'cz' | 'be', options?: any) {
        return BusinessActivityApiFp(this.configuration).bamCodes(ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Contract invoice file donwload
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {string} invoiceNumber Invoice id from external system
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessActivityApi
     */
    public contractInvoiceDownload(contractUid: string, invoiceNumber: string, ngmCountry?: 'cz' | 'be', options?: any) {
        return BusinessActivityApiFp(this.configuration).contractInvoiceDownload(contractUid, invoiceNumber, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Contract invoices search
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {number} [pageNumber] Results page number
     * @param {number} [pageSize] Results page size
     * @param {string} [fulltext] Fulltext search string
     * @param {string} [invoicedFrom] Filter invoiced from date
     * @param {string} [invoicedTill] Filter invoiced till date
     * @param {string} [dueFrom] Filter due from date
     * @param {string} [dueTill] Filter due till date
     * @param {number} [amountFrom] Transaction amount from
     * @param {number} [amountTill] Transaction amount till
     * @param {string} [invoiceNo] Invoice number
     * @param {boolean} [isPaid] Invoice was payed
     * @param {Array<string>} [locationMasterId] Location ID from the BO system
     * @param {Array<string>} [productType] Product type
     * @param {Array<string>} [invoiceType] invoice type
     * @param {string} [sortBy] Set column for sorting
     * @param {boolean} [sortDesc] Describe if sort is descendinf
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessActivityApi
     */
    public contractInvoices(contractUid: string, ngmCountry?: 'cz' | 'be', pageNumber?: number, pageSize?: number, fulltext?: string, invoicedFrom?: string, invoicedTill?: string, dueFrom?: string, dueTill?: string, amountFrom?: number, amountTill?: number, invoiceNo?: string, isPaid?: boolean, locationMasterId?: Array<string>, productType?: Array<string>, invoiceType?: Array<string>, sortBy?: string, sortDesc?: boolean, options?: any) {
        return BusinessActivityApiFp(this.configuration).contractInvoices(contractUid, ngmCountry, pageNumber, pageSize, fulltext, invoicedFrom, invoicedTill, dueFrom, dueTill, amountFrom, amountTill, invoiceNo, isPaid, locationMasterId, productType, invoiceType, sortBy, sortDesc, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create contract invoices bulk request
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {string} [invoicedFrom] Filter invoiced from date
     * @param {string} [invoicedTill] Filter invoiced till date
     * @param {string} [dueFrom] Filter due from date
     * @param {string} [dueTill] Filter due till date
     * @param {number} [amountFrom] Transaction amount from
     * @param {number} [amountTill] Transaction amount till
     * @param {boolean} [isPaid] Invoice was payed
     * @param {Array<string>} [locationMasterId] Location ID from the BO system
     * @param {Array<string>} [productType] Product type
     * @param {Array<string>} [invoiceType] invoice type
     * @param {Array<string>} [invoiceIdsOnly] Location ID from the BO system
     * @param {Array<string>} [invoiceIdsExcluded] Location ID from the BO system
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessActivityApi
     */
    public contractInvoicesCreateBulk(contractUid: string, ngmCountry?: 'cz' | 'be', invoicedFrom?: string, invoicedTill?: string, dueFrom?: string, dueTill?: string, amountFrom?: number, amountTill?: number, isPaid?: boolean, locationMasterId?: Array<string>, productType?: Array<string>, invoiceType?: Array<string>, invoiceIdsOnly?: Array<string>, invoiceIdsExcluded?: Array<string>, options?: any) {
        return BusinessActivityApiFp(this.configuration).contractInvoicesCreateBulk(contractUid, ngmCountry, invoicedFrom, invoicedTill, dueFrom, dueTill, amountFrom, amountTill, isPaid, locationMasterId, productType, invoiceType, invoiceIdsOnly, invoiceIdsExcluded, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create contract invoices export request
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {string} [fulltext] Fulltext search string
     * @param {string} [invoicedFrom] Filter invoiced from date
     * @param {string} [invoicedTill] Filter invoiced till date
     * @param {string} [dueFrom] Filter due from date
     * @param {string} [dueTill] Filter due till date
     * @param {number} [amountFrom] Transaction amount from
     * @param {number} [amountTill] Transaction amount till
     * @param {string} [invoiceNo] Invoice number
     * @param {boolean} [isPaid] Invoice was payed
     * @param {Array<string>} [locationMasterId] Location ID from the BO system
     * @param {Array<string>} [productType] Product type
     * @param {Array<string>} [invoiceType] invoice type
     * @param {string} [sortBy] Set column for sorting
     * @param {boolean} [sortDesc] Describe if sort is descendinf
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessActivityApi
     */
    public contractInvoicesCreateExport(contractUid: string, ngmCountry?: 'cz' | 'be', fulltext?: string, invoicedFrom?: string, invoicedTill?: string, dueFrom?: string, dueTill?: string, amountFrom?: number, amountTill?: number, invoiceNo?: string, isPaid?: boolean, locationMasterId?: Array<string>, productType?: Array<string>, invoiceType?: Array<string>, sortBy?: string, sortDesc?: boolean, options?: any) {
        return BusinessActivityApiFp(this.configuration).contractInvoicesCreateExport(contractUid, ngmCountry, fulltext, invoicedFrom, invoicedTill, dueFrom, dueTill, amountFrom, amountTill, invoiceNo, isPaid, locationMasterId, productType, invoiceType, sortBy, sortDesc, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get contract invoices export file
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessActivityApi
     */
    public contractInvoicesDownloadExport(contractUid: string, ngmCountry?: 'cz' | 'be', options?: any) {
        return BusinessActivityApiFp(this.configuration).contractInvoicesDownloadExport(contractUid, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Contract packages search
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {number} [pageNumber] Results page number
     * @param {number} [pageSize] Results page size
     * @param {string} [fulltext] Fulltext search string
     * @param {string} [deliveryFrom] Filter invoiced from date
     * @param {string} [deliveryTill] Filter invoiced till date
     * @param {string} [dueFrom] Filter due from date
     * @param {string} [dueTill] Filter due till date
     * @param {string} [paymentFrom] Filter payment from date
     * @param {string} [paymentTill] Filter payment till date
     * @param {number} [amountFrom] Transaction amount from
     * @param {number} [amountTill] Transaction amount till
     * @param {Array<string>} [invoiceNo] Invoice number
     * @param {Array<string>} [packageNo] package number
     * @param {Array<string>} [locationMasterId] Location ID from the BO system
     * @param {string} [deliveryChannel] Delivery channel
     * @param {string} [sortBy] Set column for sorting
     * @param {boolean} [sortDesc] Describe if sort is descendinf
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessActivityApi
     */
    public contractPackages(contractUid: string, ngmCountry?: 'cz' | 'be', pageNumber?: number, pageSize?: number, fulltext?: string, deliveryFrom?: string, deliveryTill?: string, dueFrom?: string, dueTill?: string, paymentFrom?: string, paymentTill?: string, amountFrom?: number, amountTill?: number, invoiceNo?: Array<string>, packageNo?: Array<string>, locationMasterId?: Array<string>, deliveryChannel?: string, sortBy?: string, sortDesc?: boolean, options?: any) {
        return BusinessActivityApiFp(this.configuration).contractPackages(contractUid, ngmCountry, pageNumber, pageSize, fulltext, deliveryFrom, deliveryTill, dueFrom, dueTill, paymentFrom, paymentTill, amountFrom, amountTill, invoiceNo, packageNo, locationMasterId, deliveryChannel, sortBy, sortDesc, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create contract packages export request
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {string} [fulltext] Fulltext search string
     * @param {string} [deliveryFrom] Filter invoiced from date
     * @param {string} [deliveryTill] Filter invoiced till date
     * @param {string} [dueFrom] Filter due from date
     * @param {string} [dueTill] Filter due till date
     * @param {string} [paymentFrom] Filter payment from date
     * @param {string} [paymentTill] Filter payment till date
     * @param {number} [amountFrom] Transaction amount from
     * @param {number} [amountTill] Transaction amount till
     * @param {Array<string>} [invoiceNo] Invoice number
     * @param {Array<string>} [packageNo] package number
     * @param {Array<string>} [locationMasterId] Location ID from the BO system
     * @param {string} [deliveryChannel] Delivery channel
     * @param {string} [sortBy] Set column for sorting
     * @param {boolean} [sortDesc] Describe if sort is descendinf
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessActivityApi
     */
    public contractPackagesCreateExport(contractUid: string, ngmCountry?: 'cz' | 'be', fulltext?: string, deliveryFrom?: string, deliveryTill?: string, dueFrom?: string, dueTill?: string, paymentFrom?: string, paymentTill?: string, amountFrom?: number, amountTill?: number, invoiceNo?: Array<string>, packageNo?: Array<string>, locationMasterId?: Array<string>, deliveryChannel?: string, sortBy?: string, sortDesc?: boolean, options?: any) {
        return BusinessActivityApiFp(this.configuration).contractPackagesCreateExport(contractUid, ngmCountry, fulltext, deliveryFrom, deliveryTill, dueFrom, dueTill, paymentFrom, paymentTill, amountFrom, amountTill, invoiceNo, packageNo, locationMasterId, deliveryChannel, sortBy, sortDesc, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get contract packages export file
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessActivityApi
     */
    public contractPackagesDownloadExport(contractUid: string, ngmCountry?: 'cz' | 'be', options?: any) {
        return BusinessActivityApiFp(this.configuration).contractPackagesDownloadExport(contractUid, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get list of active exports for contract and user
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessActivityApi
     */
    public contractScheduledTasks(contractUid: string, ngmCountry?: 'cz' | 'be', options?: any) {
        return BusinessActivityApiFp(this.configuration).contractScheduledTasks(contractUid, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Contract transactions claim
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {string} transactionCode Master ID of the transaction. Also known as \&#39;internal ID\&#39;
     * @param {TransactionClaimRequest} transactionClaimRequest 
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessActivityApi
     */
    public contractTransactionClaim(contractUid: string, transactionCode: string, transactionClaimRequest: TransactionClaimRequest, ngmCountry?: 'cz' | 'be', options?: any) {
        return BusinessActivityApiFp(this.configuration).contractTransactionClaim(contractUid, transactionCode, transactionClaimRequest, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Contract transaction claimable amount
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {string} transactionCode Master ID of the transaction. Also known as \&#39;internal ID\&#39;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessActivityApi
     */
    public contractTransactionClaimableAmount(contractUid: string, transactionCode: string, ngmCountry?: 'cz' | 'be', options?: any) {
        return BusinessActivityApiFp(this.configuration).contractTransactionClaimableAmount(contractUid, transactionCode, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Contract transactions search
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {number} [pageNumber] Results page number
     * @param {number} [pageSize] Results page size
     * @param {string} [fulltext] Fulltext search string
     * @param {string} [note] Filter note search string
     * @param {string} [createdFrom] Filter created from date
     * @param {string} [createdTill] Filter created till date
     * @param {number} [amountFrom] Transaction amount from
     * @param {number} [amountTill] Transaction amount till
     * @param {Array<string>} [invoiceNo] Invoice number
     * @param {Array<string>} [locationMasterId] Location ID from the BO system
     * @param {string} [orderMasterId] Order ID from the BO system
     * @param {string} [extPaymentId] Id of the original claim request from NGM.
     * @param {Array<string>} [terminalMasterId] POS Terminal ID from the BO system
     * @param {Array<string>} [physicalTerminalId] POS Terminal ID from the BO system
     * @param {Array<string>} [authCode] Authorization codes. Also known as \&#39;approval code\&#39;.
     * @param {Array<string>} [productCode] Product codes
     * @param {Array<string>} [transactionType] Transaction types
     * @param {Array<string>} [paymentMethod] Payment methods
     * @param {string} [sortBy] Set column for sorting
     * @param {Array<TransactionStatusEnum>} [status] Status of the transactions
     * @param {boolean} [sortDesc] Describe if sort is descendinf
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessActivityApi
     */
    public contractTransactions(contractUid: string, ngmCountry?: 'cz' | 'be', pageNumber?: number, pageSize?: number, fulltext?: string, note?: string, createdFrom?: string, createdTill?: string, amountFrom?: number, amountTill?: number, invoiceNo?: Array<string>, locationMasterId?: Array<string>, orderMasterId?: string, extPaymentId?: string, terminalMasterId?: Array<string>, physicalTerminalId?: Array<string>, authCode?: Array<string>, productCode?: Array<string>, transactionType?: Array<string>, paymentMethod?: Array<string>, sortBy?: string, status?: Array<TransactionStatusEnum>, sortDesc?: boolean, options?: any) {
        return BusinessActivityApiFp(this.configuration).contractTransactions(contractUid, ngmCountry, pageNumber, pageSize, fulltext, note, createdFrom, createdTill, amountFrom, amountTill, invoiceNo, locationMasterId, orderMasterId, extPaymentId, terminalMasterId, physicalTerminalId, authCode, productCode, transactionType, paymentMethod, sortBy, status, sortDesc, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Contract transactions count
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {string} [note] Filter note search string
     * @param {string} [fulltext] Fulltext search string
     * @param {string} [createdFrom] Filter created from date
     * @param {string} [createdTill] Filter created till date
     * @param {number} [amountFrom] Transaction amount from
     * @param {number} [amountTill] Transaction amount till
     * @param {Array<string>} [invoiceNo] Invoice number
     * @param {Array<string>} [locationMasterId] Location ID from the BO system
     * @param {string} [orderMasterId] Order ID from the BO system
     * @param {Array<string>} [terminalMasterId] POS Terminal ID from the BO system
     * @param {Array<string>} [physicalTerminalId] Physical terminal ID from the BO system
     * @param {Array<string>} [authCode] Authorization codes. Also known as \&#39;approval code\&#39;.
     * @param {Array<string>} [productCode] Product codes
     * @param {Array<string>} [transactionType] Transaction types
     * @param {Array<string>} [paymentMethod] Payment methods
     * @param {Array<TransactionStatusEnum>} [status] Status of the transactions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessActivityApi
     */
    public contractTransactionsCount(contractUid: string, ngmCountry?: 'cz' | 'be', note?: string, fulltext?: string, createdFrom?: string, createdTill?: string, amountFrom?: number, amountTill?: number, invoiceNo?: Array<string>, locationMasterId?: Array<string>, orderMasterId?: string, terminalMasterId?: Array<string>, physicalTerminalId?: Array<string>, authCode?: Array<string>, productCode?: Array<string>, transactionType?: Array<string>, paymentMethod?: Array<string>, status?: Array<TransactionStatusEnum>, options?: any) {
        return BusinessActivityApiFp(this.configuration).contractTransactionsCount(contractUid, ngmCountry, note, fulltext, createdFrom, createdTill, amountFrom, amountTill, invoiceNo, locationMasterId, orderMasterId, terminalMasterId, physicalTerminalId, authCode, productCode, transactionType, paymentMethod, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create contract transactions export request
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {string} [note] Filter note search string
     * @param {string} [createdFrom] Filter created from date
     * @param {string} [createdTill] Filter created till date
     * @param {number} [amountFrom] Transaction amount from
     * @param {number} [amountTill] Transaction amount till
     * @param {Array<string>} [invoiceNo] Invoice number
     * @param {Array<string>} [locationMasterId] Location ID from the BO system
     * @param {string} [orderMasterId] Order ID from the BO system
     * @param {Array<string>} [physicalTerminalId] POS Terminal ID from the BO system
     * @param {Array<string>} [terminalMasterId] POS Terminal ID from the BO system
     * @param {Array<string>} [authCode] Authorization codes. Also known as \&#39;approval code\&#39;.
     * @param {Array<string>} [productCode] Product codes
     * @param {Array<string>} [transactionType] Transaction types
     * @param {Array<string>} [paymentMethod] Payment methods
     * @param {string} [sortBy] Set column for sorting
     * @param {Array<TransactionStatusEnum>} [status] Status of the transactions
     * @param {boolean} [sortDesc] Describe if sort is descendinf
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessActivityApi
     */
    public contractTransactionsCreateExport(contractUid: string, ngmCountry?: 'cz' | 'be', note?: string, createdFrom?: string, createdTill?: string, amountFrom?: number, amountTill?: number, invoiceNo?: Array<string>, locationMasterId?: Array<string>, orderMasterId?: string, physicalTerminalId?: Array<string>, terminalMasterId?: Array<string>, authCode?: Array<string>, productCode?: Array<string>, transactionType?: Array<string>, paymentMethod?: Array<string>, sortBy?: string, status?: Array<TransactionStatusEnum>, sortDesc?: boolean, options?: any) {
        return BusinessActivityApiFp(this.configuration).contractTransactionsCreateExport(contractUid, ngmCountry, note, createdFrom, createdTill, amountFrom, amountTill, invoiceNo, locationMasterId, orderMasterId, physicalTerminalId, terminalMasterId, authCode, productCode, transactionType, paymentMethod, sortBy, status, sortDesc, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get contract transactions export file
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessActivityApi
     */
    public contractTransactionsDownloadExport(contractUid: string, ngmCountry?: 'cz' | 'be', options?: any) {
        return BusinessActivityApiFp(this.configuration).contractTransactionsDownloadExport(contractUid, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Performance business report
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {'year' | 'month'} displayedBy Choose view type
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {number} [pageNumber] Results page number
     * @param {number} [pageSize] Results page size
     * @param {Array<string>} [locationMasterId] Location ID from the BO system
     * @param {'year' | 'month'} [sortBy] Set column for sorting
     * @param {boolean} [sortDesc] Describe if sort is descending
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessActivityApi
     */
    public performanceReport(contractUid: string, displayedBy: 'year' | 'month', ngmCountry?: 'cz' | 'be', pageNumber?: number, pageSize?: number, locationMasterId?: Array<string>, sortBy?: 'year' | 'month', sortDesc?: boolean, options?: any) {
        return BusinessActivityApiFp(this.configuration).performanceReport(contractUid, displayedBy, ngmCountry, pageNumber, pageSize, locationMasterId, sortBy, sortDesc, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create contract performance report export request
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {'year' | 'month'} displayedBy Choose view type
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {Array<string>} [locationMasterId] Location ID from the BO system
     * @param {'year' | 'month'} [sortBy] Set column for sorting
     * @param {boolean} [sortDesc] Describe if sort is descending
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessActivityApi
     */
    public performanceReportCreateExport(contractUid: string, displayedBy: 'year' | 'month', ngmCountry?: 'cz' | 'be', locationMasterId?: Array<string>, sortBy?: 'year' | 'month', sortDesc?: boolean, options?: any) {
        return BusinessActivityApiFp(this.configuration).performanceReportCreateExport(contractUid, displayedBy, ngmCountry, locationMasterId, sortBy, sortDesc, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get contract performance report export file
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessActivityApi
     */
    public performanceReportDownloadExport(contractUid: string, ngmCountry?: 'cz' | 'be', options?: any) {
        return BusinessActivityApiFp(this.configuration).performanceReportDownloadExport(contractUid, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Request for push the contract transactions list into the BAM, from specific date till oldest records in the database
     * @summary Submit request for historical contract transactions
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {string} [createdTill] Records created from date
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessActivityApi
     */
    public transactionsHistoryRequest(contractUid: string, createdTill?: string, ngmCountry?: 'cz' | 'be', options?: any) {
        return BusinessActivityApiFp(this.configuration).transactionsHistoryRequest(contractUid, createdTill, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get status of the latest request to obtain historical transations from the BO and timestamp of the latest available record (related with the contract)
     * @summary Status of the latest request to historical transations
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessActivityApi
     */
    public transactionsHistoryState(contractUid: string, ngmCountry?: 'cz' | 'be', options?: any) {
        return BusinessActivityApiFp(this.configuration).transactionsHistoryState(contractUid, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }
}
